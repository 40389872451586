import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { ContentBox, ContentBoxHead, ContentBoxBody, getCMSObject, formatDate, HideFromView, AlertBox, IfLoggedIn, IfLoggedOut } from 'sg-ui-components';
import './dashboard.scss';

import { Container } from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';
import promotionConfig from '../promotionConfig';
import Menu from './Menu';
import ClickOutsideComponentEvent from '../utils/ClickOutsideComponentEvent';

/**********************************************************************
 * Component:  PointsDashboard
 * Purpose:    Dashboard component that shows site wide.
 *             If the user is not loggedin - simply displays a login/register button
 *             If user is logged in - displays points information and a sub-menu of
 *             pages user can now navigate too.
 *
 * Props:       user -  user data store
 *              config - config data store
 *              actions - store actions (apis)
 *
 * APIs used:   userActions.getDashboard() - get Dashboard info
 *
 *  Notes:
 */
const PointsDashboard = ({ user, config, actions }) => {
    const [accountNavDisplayed, setAccountNavDisplayed] = useState(false);
    const [error, setError] = useState('');
    const [aboutToExpire, setAboutToExpire] = useState(false);
    const coinReference = getCMSObject('data.components.teleScripts.myPointsHistory.jsonBlock');

    // set size of Dashboard based on how many elements are in it.
    let dashboardColSize = 'col-lg-10 col-xl-8 mx-auto';
    if (promotionConfig?.dashboardComponents?.length === 1) {
        dashboardColSize = 'col-md-8 col-lg-5 col-xl-4 mx-auto';
    }

    //***********************************************************************************
    // Get the dashboard and report any errors
    const getDashboard = async () => {
        await actions.userActions.getDashboard();
        if (user.errors) {
            setError(user.errors);
        }
    };

    //***********************************************************************************
    // Only call getDashbord for logged in players
    useEffect(() => {
        //? note: RAM user has no user.player.actions
        if (user.loggedIn && (user?.player?.actions?.length === 0 || user?.drawPoints === 'N/A')) {
            getDashboard();
        }
    }, [user.loggedIn]);

    //***********************************************************************************
    // OnClick handler to show/hide the dashboard navigation
    const showAccountNav = async () => {
        const toggleNav = !accountNavDisplayed;
        setAccountNavDisplayed(toggleNav);
    };

    //***********************************************************************************
    // OnMouseLeave handler to hide the dashboard navigation
    const hideAccountNav = async () => {
        setAccountNavDisplayed(false);
    };

    //***********************************************************************************
    // Draw Points Widget - navigates to MyActivity when clicked
    const DrawPoints = () => {
        return (
            <div className='points-dashboard-item'>
                <label htmlFor='drawPoints'>{coinReference?.pointReference ?? ''}</label>
                <HashLink className='btn theme-btn' id='drawPoints' to='/my-activity#points-history'>
                    {user?.drawPoints ?? 0}
                </HashLink>
            </div>
        );
    };

    //***********************************************************************************
    // Prize Points Widget - navigates to MyActivity when clicked
    const PrizePoints = () => {
        return (
            <div className='points-dashboard-item'>
                <label htmlFor='PrizePoints'>{coinReference?.pointReference ?? ''}</label>
                <Link className='btn theme-btn inset' id='PrizePoints' to='/my-activity#points-history'>
                    {user.prizePoints}
                </Link>
            </div>
        );
    };

    //***********************************************************************************
    // Expiring (prize) Points Widget - navigates to feedback form when clicked
    const ExpiringPoints = () => {
        // Check if the points are expiring soon.
        const today = new Date();
        if (parseInt(user.pointsExpiringDate)) {
            const expiringDates = new Date(user.pointsExpiringDate * 1000);
            let daysLeft = Math.abs(expiringDates - today);
            daysLeft = Math.floor(daysLeft / (1000 * 60 * 60 * 24));

            if (daysLeft < config.config.days_until_points_expire) {
                setAboutToExpire(true);
            }
        }

        return (
            <div className={`points-dashboard-item mt-3 mt-md-0 ${aboutToExpire ? 'expiring' : ''}`}>
                <label htmlFor={`ExpiringPoints ${aboutToExpire ? 'expiring' : ''}`}>Expiring {coinReference?.pointReference ?? ''}</label>
                <HashLink className={`btn theme-btn inset ${aboutToExpire ? 'expiring' : ''}`} id='ExpiringPoints' to='/feedback#rules'>
                    {user.pfpExpiring}
                </HashLink>
                <small className={`theme-text-muted ${aboutToExpire ? 'expiring' : ''}`}>
                    Expires: {user.pointsExpiringDate ? formatDate?.monthDayYear(user.pointsExpiringDate) : ''}
                </small>
            </div>
        );
    };

    //***********************************************************************************
    // Login Button Widget - redirects to login page
    const LoginButton = () => {
        return (
            <div className='col-md-6 text-center'>
                <Link className='logged-out-btn btn theme-btn theme-secondary text-uppercase' id='Login' to='/login'>
                    Login
                </Link>
            </div>
        );
    };

    //***********************************************************************************
    // Register Button Widget - redirects to registration page
    const RegisterButton = () => {
        return (
            <div className='col-md-6 text-center'>
                <Link type='button' className='logged-out-btn btn theme-btn theme-primary text-uppercase' id='Register' to='/register'>
                    Sign Up
                </Link>
            </div>
        );
    };

    //***********************************************************************************
    // My Account Navigation widget -  provides user a list of pages they can go when
    // logged in.   Shows/Hides on click.
    const MyAccount = () => {
        const accountMenuRef = useRef(null);
        const dashboardMenu = getCMSObject('dashboardMenu', 'webMenu');

        return (
            <div ref={accountMenuRef} className='points-dashboard-item my-account mt-md-0'>
                <label className='d-block' htmlFor='showAccountNav'>
                    My Players Club
                </label>
                <FaUserCircle className='my-account-img' onClick={showAccountNav} alt='account icon' />
                <Menu menu_json={dashboardMenu} id='showAccountNav' className={`nav flex-column account-nav ${accountNavDisplayed ? 'd-block' : 'd-none'} `} />
                <ClickOutsideComponentEvent ref={accountMenuRef} callback={hideAccountNav} />
            </div>
        );
    };

    return (
        <HideFromView paths={['/login', '/register', '/forgot-password']}>
            <Container>
                <div className='points-dashboard mt-3'>
                    <IfLoggedIn user={user}>
                        <div className={dashboardColSize}>
                            <ContentBox variant='logged-in-dashboard'>
                                <ContentBoxHead>
                                    <span className='welcome-text'>Welcome</span>
                                    <span className='user-name'> {user?.player?.first_name ?? user?.player?.firstname ?? 'Player'}!</span>
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    <AlertBox message={error} />
                                    <div className='row justify-content-between'>
                                        {promotionConfig?.dashboardComponents?.includes('pfd') ? <DrawPoints /> : null}
                                        {promotionConfig?.dashboardComponents?.includes('pfp') ? <PrizePoints /> : null}
                                        {promotionConfig?.dashboardComponents?.includes('expiring') ? <ExpiringPoints /> : null}
                                        <MyAccount />
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </IfLoggedIn>
                    <IfLoggedOut user={user}>
                        <div className='col-md-8 col-lg-6 col-xl-5 mx-auto'>
                            <ContentBox variant='theme-red-tint'>
                                <ContentBoxHead>
                                    <span className='welcome-text'>Welcome!</span>
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    <div className='row justify-content-center p-md-3'>
                                        <RegisterButton />
                                        <LoginButton />
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </IfLoggedOut>
                </div>
            </Container>
        </HideFromView>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsDashboard);
