import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BoxedContent, TableDesktop, TableMobile, getCMSObject } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';

const PastDrawings = ({ hasCollapser }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setPageLoaded(true);
    }, []);

    const telescriptPastDrawings = getCMSObject('data.components.teleScripts.PastDrawings.jsonBlock');

    const dataFormatted = getCMSObject('data.drawingsAndWinners.pastDrawings.jsonBlock') ?? [];

    // Headers: Define your headers in an object
    // Rows: Add fields based on headers and the data
    const entriesTable = (data) => {
        const drawings = data ?? [];
        const displayHeaders = {
            promotion: telescriptPastDrawings?.promotion ?? 'Promotion',
            entryPeriod: telescriptPastDrawings?.entryPeriod ?? 'Entry Period',
            drawingDate: telescriptPastDrawings?.drawingDate ?? 'Drawing Date',
        };
        const displayRows = [...drawings].map((drawing) => ({
            ...drawing,
            fields: {
                promotion: drawing?.promotionUrl ? (
                    <a href={drawing?.promotionUrl} rel='noreferrer' target='_blank'>
                        {drawing?.promotion}
                    </a>
                ) : (
                    drawing?.promotion
                ),
                entryPeriod: drawing?.entryPeriod,
                drawingDate: (
                    <>
                        <div>{drawing?.drawingDate}</div>
                        {drawing?.winnersUrl && (
                            <div>
                                <a href={drawing?.winnersUrl} rel='noreferrer' target='_blank'>
                                    View Winners
                                </a>
                            </div>
                        )}
                    </>
                ),
            },
        }));

        const displayOptions = {
            tableId: 'recent-drawings-table',
            isLoaded: pageLoaded,
            messageNoRows: telescriptPastDrawings?.noDrawings ?? 'No drawings',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
            </>
        );
    };

    if (dataFormatted && dataFormatted.length) {
        return (
            <BoxedContent
                note='Past Drawings (SCBZ drawing with external links)'
                hash='recent-drawings'
                isActive={true}
                title={telescriptPastDrawings?.title ?? 'Past Drawings'}
                hasCollapser={hasCollapser}>
                <div className='row'>
                    <div className='col'>{entriesTable(dataFormatted)}</div>
                </div>
            </BoxedContent>
        );
    } else return <></>;
};

export default connect(mapStateToProps, mapDispatchToProps)(PastDrawings);

//? TeleScript object:
/*
const telescriptPastDrawings = {
    "title": "Past Drawings",
}
*/
