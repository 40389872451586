import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { Row, Col } from 'react-bootstrap';
import { IfLoggedIn, IfLoggedOut, TeleScript, getCMSObject } from 'sg-ui-components';
import facebook from '../../../assets/images/facebook.png';
import instagram from '../../../assets/images/instagram.png';
import x_logo from '../../../assets/images/x-logo.png';
import youtube from '../../../assets/images/youtube.png';
import linkedin from '../../../assets/images/linkedin.png';
import scel_logo from '../../../assets/images/scel-logo.png';
import playersclub_logo from '../../../assets/images/sclogo500.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCog } from '@fortawesome/pro-regular-svg-icons'; //FaSignOutAlt for login/out button
import { Squash as Hamburger } from 'hamburger-react';
import { PrimaryNav } from '../Header/index';
import MobileBreadcrumbs from './MobileBreadcrumbs';

const WrapperHeader = ({ user, path, config }) => {
    const [isOpen, setOpen] = useState(false);
    const lotteryBaseUrl = config?.config?.lottery_host ?? 'https://www.sceducationlottery.com';
    const lotteryUrl = user?.loggedIn ? `/redirect?url=${lotteryBaseUrl}` : lotteryBaseUrl;

    const storeUrl = config?.config?.partner_map?.store ?? 'https://www.sceducationlottery.com';

    return (
        <div className='wrapper-header'>
            {/* Begin Mobile */}
            {/* Toggling class for main mobile slideout menu: className="menu mobile-only open" */}
            <div id='showMainMenu' className='menu mobile-only'>
                <nav className='nav-main'>
                    <div className='nav-container'>
                        <ul className='top-level'>
                            <TeleScript
                                note='mobile version of primary nav'
                                line={getCMSObject('data.lotteryWrapper.wrapperHeader.wrapperHeaderMobileNavMainTop.contentHTMLCode')}
                                replace={{
                                    '%LOTTERY_URL%': lotteryUrl,
                                    '%STORE_URL%': storeUrl,
                                }}
                            />
                            <li className='playersclub-buttons-mobile'>
                                <IfLoggedIn user={user}>
                                    <div className='welcome-box'>
                                        <span className='welcome-text'>Welcome</span>
                                        <span className='user-name'> {user?.player?.first_name ?? user?.player?.firstname ?? 'Player'}!</span>
                                        <br />
                                    </div>
                                    <a href='/logout'>
                                        <button type='submit' className='PlayersClubHeaderButtons_left'>
                                            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                                        </button>
                                    </a>
                                    <a href={`${lotteryUrl}/PlayersClub/AccountUpdate`}>
                                        <button type='submit' className='PlayersClubHeaderButtons_right'>
                                            <FontAwesomeIcon icon={faUserCog} /> Account
                                        </button>
                                    </a>
                                </IfLoggedIn>
                                <IfLoggedOut user={user}>
                                    <a href='/login'>
                                        <button type='submit' className='PlayersClubHeaderButtons_left'>
                                            <FontAwesomeIcon icon={faSignOutAlt} /> Login
                                        </button>
                                    </a>
                                    <a href='/register'>
                                        <button type='submit' className='PlayersClubHeaderButtons_right'>
                                            <FontAwesomeIcon icon={faUserCog} /> Register
                                        </button>
                                    </a>
                                </IfLoggedOut>
                            </li>
                            <TeleScript
                                note='mobile version of trapezoid menu'
                                line={getCMSObject('data.lotteryWrapper.wrapperHeader.wrapperHeaderMobileNavMainMiddle.contentHTMLCode')}
                                replace={{
                                    '%LOTTERY_URL%': lotteryUrl,
                                    '%STORE_URL%': storeUrl,
                                }}
                            />
                            {/* Images may ultimately hosted in file manager/CMS */}
                            <li className='social-icons'>
                                <a href='https://www.facebook.com/SouthCarolinaEducationLottery' rel='noreferrer' target='_blank'>
                                    <img alt='Facebook' src={facebook} />
                                </a>
                                <a href='https://www.instagram.com/sclottery' rel='noreferrer' target='_blank'>
                                    <img alt='Instagram' src={instagram} />
                                </a>
                                <a href='https://twitter.com/sclottery' rel='noreferrer' target='_blank'>
                                    <img alt='X' src={x_logo} />
                                </a>
                                <a href='https://www.youtube.com/channel/UCj17BuqNKuShWzjV6EGlSRA' rel='noreferrer' target='_blank'>
                                    <img alt='YouTube' src={youtube} />
                                </a>
                                <a href='https://www.linkedin.com/company/south-carolina-education-lottery' rel='noreferrer' target='_blank'>
                                    <img alt='LinkedIn' src={linkedin} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            {/* Mobile header */}
            <div className='mobile-header mobile-only layout-scroll-offset'>
                <div className='menu-bar'>
                    <a href={`${lotteryUrl}`} rel='noreferrer'>
                        <img className='South Carolina Education Lottery' src={scel_logo} alt='South Carolina Education Lottery Logo' />
                    </a>
                    {/* Using basic javascript in index.html to toggle menu class. May be refactored for CMS. For now using React Hamburger */}
                    <button
                        type='button'
                        onClick={window['showMainMenu']}
                        className='button-nav-toggle navbar-toggle collapsed mobile-only'
                        data-toggle='collapse'
                        data-target='#navbar'
                        aria-expanded='false'
                        aria-controls='navbar'
                        data-di-id='di-id-f36294c7-92f42428'>
                        <p>Menu</p>
                        <span className='sr-only'>Toggle navigation</span>
                        <Hamburger className='hamburger-menu-icon' size={20} toggled={isOpen} toggle={setOpen} />
                    </button>
                </div>

                {/* Breadcrumb prototype mobile markup/css */}
                <MobileBreadcrumbs path={path} />
            </div>
            {/* End Mobile */}

            {/* Begin Desktop */}
            <div className='top-nav no-mobile'>
                <div className='container'>
                    {/* Images may ultimately hosted in file manager/CMS */}
                    <div className='social-icons'>
                        <a href='https://www.facebook.com/SouthCarolinaEducationLottery' rel='noreferrer' target='_blank'>
                            <img alt='Facebook' src={facebook} />
                        </a>
                        <a href='https://www.instagram.com/sclottery' rel='noreferrer' target='_blank'>
                            <img alt='Instagram' src={instagram} />
                        </a>
                        <a href='https://twitter.com/sclottery' rel='noreferrer' target='_blank'>
                            <img alt='X' src={x_logo} />
                        </a>
                        <a href='https://www.youtube.com/channel/UCj17BuqNKuShWzjV6EGlSRA' rel='noreferrer' target='_blank'>
                            <img alt='YouTube' src={youtube} />
                        </a>
                        <a href='https://www.linkedin.com/company/south-carolina-education-lottery' rel='noreferrer' target='_blank'>
                            <img alt='LinkedIn' src={linkedin} />
                        </a>
                    </div>
                    <div className='playersclub-buttons'>
                        <IfLoggedIn user={user}>
                            <Row>
                                <Col lg={3}>
                                    <img src={playersclub_logo} className='players-club-logo' alt='Players Club' />
                                </Col>
                                <Col lg={9}>
                                    <a href='/logout'>
                                        <button type='submit' className='PlayersClubHeaderButtons_left'>
                                            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                                        </button>
                                    </a>
                                    <a href={`${lotteryUrl}/PlayersClub/AccountUpdate`}>
                                        <button type='submit' className='PlayersClubHeaderButtons_right'>
                                            <FontAwesomeIcon icon={faUserCog} /> Account
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                        </IfLoggedIn>
                        <IfLoggedOut user={user}>
                            <img src={playersclub_logo} className='players-club-logo' alt='Players Club' />
                            <a href='/login'>
                                <button type='submit' className='PlayersClubHeaderButtons_left'>
                                    <FontAwesomeIcon icon={faSignOutAlt} /> Login
                                </button>
                            </a>
                            <a href='/register'>
                                <button type='submit' className='PlayersClubHeaderButtons_right'>
                                    <FontAwesomeIcon icon={faUserCog} /> Register
                                </button>
                            </a>
                        </IfLoggedOut>
                    </div>
                    <TeleScript
                        note='desktop version of primary nav'
                        line={getCMSObject('data.lotteryWrapper.wrapperHeader.wrapperHeaderDesktopNavMain.contentHTMLCode')}
                        replace={{
                            '%LOTTERY_URL%': lotteryUrl,
                            '%SCEL_LOGO%': scel_logo,
                        }}
                    />
                </div>
            </div>
            <PrimaryNav storeUrl={storeUrl} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperHeader);
