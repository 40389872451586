import React from 'react';
import { Link } from 'react-router-dom';
import { stripsTagsAndSlashes } from 'sg-ui-components';
import { routes } from '../../../promotionConfig';

const MobileBreadcrumbs = ({ path }) => {
    const data = path ? routes[stripsTagsAndSlashes(path)] : {};

    return data?.breadcrumb && data?.breadcrumb.length ? (
        <div className='scel-bread-crumb'>
            <ul>
                {data?.breadcrumb?.map(({ name, path }, key) =>
                    key + 1 === data?.breadcrumb?.length ? (
                        <li key={key}>{name}</li>
                    ) : (
                        <li key={key}>
                            <Link to={path}>{name}</Link> &gt;
                        </li>
                    )
                )}
            </ul>
        </div>
    ) : (
        <div className='scel-bread-crumb'>
            <ul>
                <li>
                    <Link to={'/'}>Home</Link>
                </li>
            </ul>
        </div>
    );
};

export default MobileBreadcrumbs;
