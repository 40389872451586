import React from 'react';
import { FindScratchNumbers, FindDrawNumbers } from 'sg-ui-components';
import promotionConfig from '../../promotionConfig';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

const TicketSamples = ({ cmsSourceFirebase }) => {
    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                {promotionConfig.scratchTicketFields ? <FindScratchNumbers.FindScratchNumbers cmsSourceFirebase={cmsSourceFirebase} /> : null}
                {promotionConfig.drawFields ? <FindDrawNumbers.FindDrawNumbers cmsSourceFirebase={cmsSourceFirebase} /> : null}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketSamples);
