import React from 'react';
import { connect } from 'react-redux';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import PostClaim from '../Components/Postclaim/PostClaim';

const PostClaimPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <PostClaim />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaimPage);
