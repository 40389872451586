import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavDropdown, Dropdown } from 'react-bootstrap';
import { getCMSObject, isApp } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import { NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import HomeHeroBanner from '../../HomeHeroBanner';
import PointsDashboard from '../../PointsDashboard';
import { UserMenu } from './UserMenu';
import { isArray } from 'lodash';

const Header = () => {
    return (
        <div className='page-header'>
            <header>
                <div className={isApp() ? 'mb-4' : 'mb-dynamic'}>
                    <div className='clearfix' />
                    <HomeHeroBanner />
                    <PointsDashboard />
                </div>
            </header>
        </div>
    );
};

const PrimaryNavTemplate = ({ storeUrl }) => {
    const primaryNavFromCMS = getCMSObject('data.sitewideSettings.primaryNav.jsonBlock') ?? [];
    const mainMenu = isArray(primaryNavFromCMS) ? primaryNavFromCMS : [];
    const location = useLocation();

    return (
        <div className='sub-nav no-mobile'>
            <div className='container'>
                <div className='trapezoid'>
                    <ul>
                        {mainMenu.map((link, key) => {
                            if (link.subMenu === undefined) {
                                if (link.uri === 'external-store-url') {
                                    return (
                                        <li key={`main-menu--${key}`}>
                                            <a href={`${storeUrl}/drawing/landing.php`} className='text-capitalize'>
                                                {link.title}
                                            </a>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li key={`main-menu--${key}`}>
                                            <NavLink to={link.uri} activeclassname='active'>
                                                {link.title}
                                            </NavLink>
                                        </li>
                                    );
                                }
                            } else if (link.subMenu.length) {
                                return (
                                    <li className='has-dropdown' key={`main-menu--${key}`}>
                                        <NavDropdown
                                            active={location.pathname === '/feedback'}
                                            activeclassname='active'
                                            alignRight
                                            key={`dropdown-${key}`}
                                            title={link.title}
                                            className='nav-submenu'>
                                            {link.subMenu.map((subLink, key) => {
                                                return (
                                                    <NavDropdown.Item
                                                        key={`sub-menu--${key}`}
                                                        as={NavHashLink}
                                                        to={subLink.uri + '#' + subLink.anchor}
                                                        activeclassname='active'>
                                                        {subLink.title}
                                                    </NavDropdown.Item>
                                                );
                                            })}
                                        </NavDropdown>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const PrimaryNav = connect(mapStateToProps, mapDispatchToProps)(PrimaryNavTemplate);

const MobilePrimaryNavTemplate = () => {
    const [toggleText, setToggleText] = useState('MENU');

    const primaryNavFromCMS = getCMSObject('data.sitewideSettings.primaryNav.jsonBlock') ?? [];
    const mobileMainMenu = isArray(primaryNavFromCMS) ? primaryNavFromCMS : [];

    return (
        <div className='d-flex d-primary-nav-none mobile-nav-row'>
            <Dropdown drop='down' id='mobile-nav-dropdown' onSelect={(ek, ev) => setToggleText(ev.target.innerText)} className='flex-grow-1'>
                <Dropdown.Toggle id='mobile-nav-toggle' className='w-100' variant>
                    {toggleText}
                </Dropdown.Toggle>
                <Dropdown.Menu className='mobile-dropdown-menu'>
                    {mobileMainMenu.map((link, key) => {
                        return (
                            <Dropdown.Item key={`mobile-menu--${key}`} as={NavLink} to={link.uri} activeclassname='active' className='nav-link'>
                                {link.title}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <UserMenu />
        </div>
    );
};

const MobilePrimaryNav = connect(mapStateToProps, mapDispatchToProps)(MobilePrimaryNavTemplate);

export { Header, PrimaryNav, MobilePrimaryNav };
