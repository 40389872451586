/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AnimateCanvas from '../AnimateCanvas';
import VirtualCounter from './VirtualCounter';
import { VirtualCounterManager } from './VirtualCounterManager';

const CREATEJS_LIB = 'createjs-2015.11.26.min.js';

const RollingCount = (props) => {
    const [rollingCounter, setRollingCounter] = useState();
    const { digits, space, height, jackpots, ...rest } = props;

    const containerRef = useRef();
    const canvasRef = useRef();

    const discriminator = (Math.random() * Date.now()).toString(16).slice(0, 5).toUpperCase();

    // Ugly loop to detect when the rollingCounter instance is ready
    // useEffect(() => {
    //     let timer = setInterval(() => {
    //         if (window.rollingCounter) {
    //             clearInterval(timer);
    //             timer = undefined;

    //             setRollingCounter(window.rollingCounter);
    //         }
    //     }, 100);
    //     return () => {
    //         timer && clearInterval(timer);

    //         // Clean-up on behalf of the animation script
    //         window.rollingCounter && window.rollingCounter.destroy();
    //         delete window.rollingCounter;
    //     };
    // }, []);

    // Apply the amountStart prop
    useEffect(() => {
        if (!rollingCounter || !props.jackpots) return;

        if (!rollingCounter) {
            return console.warn(`rollingCounter is falsey. RollingCounter: ${rollingCounter}`);
        }

        if (rollingCounter.length != props.jackpots.length) {
            return console.error('Mismatch in count of counters and api jackpots');
        }

        if (rollingCounter.every((rc) => rc.initialised)) {
            return; //console.warn(`All rolling counters already initialised.`)
        }

        // gather elements to be affected
        const counterWrapper = rollingCounter[0].parent; // document.querySelector('.counter--wrapper');

        // apply code unhiding the first two counters
        const counters = counterWrapper.querySelectorAll('.counter--element');
        const multipleCounters = counters.length > 1;
        counters[0].classList.add('counter--element__start');
        if (multipleCounters) {
            counters[1].classList.add('counter--element__end');
            counterWrapper.classList.add(`counter--wrapper__animating-${discriminator}`);
        }

        // gather elements to be affected
        const startCounter = counterWrapper.querySelector('.counter--element__start');
        let endCounter;
        if (multipleCounters) {
            endCounter = counterWrapper.querySelector('.counter--element__end');
        }

        // apply styles
        if (multipleCounters) {
            // calc style values
            const { height } = startCounter.querySelector('canvas').getBoundingClientRect();
            const halfHeight = height / 2;

            startCounter.style.transform = `translateZ(${halfHeight}px)`;
            endCounter.style.position = 'absolute';
            endCounter.style.transform = `rotateX(90deg) translateZ(${halfHeight}px)`;
            counterWrapper.style.transformOrigin = `0 ${halfHeight}px 0`;
        }

        // _resize();

        const options = {
            root: document.documentElement,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const startCounter = entry.target.querySelector('.counter--element__start');
                    const startCanvas = startCounter?.querySelector('canvas');
                    const endCounter = entry.target.querySelector('.counter--element__end');
                    const endCanvas = endCounter?.querySelector('canvas');
                    if (startCanvas && startCounter) {
                        const rect = startCanvas.getBoundingClientRect();
                        const height = rect.height;
                        counterWrapper.style.transformOrigin = `0 ${height / 2}px 0`;
                        startCounter.style.transform = `translateZ(${height / 2}px)`;
                    }
                    if (startCanvas && endCounter) {
                        const rect = startCanvas.getBoundingClientRect();
                        const height = rect.height;
                        endCounter.style.transform = `rotateX(90deg) translateZ(${height / 2}px)`;
                    }
                }
            });
        }, options);

        observer.observe(counterWrapper);

        if (multipleCounters) {
            counterWrapper?.addEventListener('animationiteration', () => {
                // gather
                const counters = counterWrapper.querySelectorAll('.counter--element');
                const lastStart = counterWrapper.querySelector('.counter--element__start');
                const lastEnd = counterWrapper.querySelector('.counter--element__end');

                // calc
                const lastEndIndex = Array.from(counters).indexOf(lastEnd);
                const newEndIndex = (lastEndIndex + 1) % counters.length;

                // apply
                lastStart?.classList.remove('counter--element__start');
                lastEnd?.classList.remove('counter--element__end');
                lastEnd?.classList.add('counter--element__start');
                counters[newEndIndex].classList.add('counter--element__end');

                const { height } = counters[newEndIndex].querySelector('canvas').getBoundingClientRect();
                counters.forEach((counter) => {
                    counter.style.transform = '';
                });
                counters[newEndIndex].style.transform = `rotateX(90deg) translateZ(${height / 2}px)`;
                counterWrapper.style.transformOrigin = `0 ${height / 2}px 0`;

                // reflow
                // _resize();
            });
        }

        props.jackpots.forEach((jackpot, index) => {
            let targetMeter = null;

            const unintialisedMeters = rollingCounter.filter((rc) => {
                return !rc.initialised;
            });
            const intialisedMeters = rollingCounter.filter((rc) => {
                return rc.initialised;
            });

            if (jackpot.id !== undefined && jackpot.id !== null) {
                targetMeter = unintialisedMeters.find((rc) => {
                    return rc.id === jackpot.id;
                });
            }

            if (!targetMeter && jackpot.skin !== undefined && jackpot.skin != null) {
                targetMeter = unintialisedMeters.find((rc) => {
                    return rc.skinId === counterSkinMap[jackpot.skin] && (rc.id === undefined || rc.id === null);
                });
            }

            if (targetMeter) {
                if (jackpot.url) {
                    targetMeter.getJackpot(jackpot.url);
                } else if (jackpot.value != undefined) {
                    targetMeter.setStaticJackpot(jackpot.value);
                }
            }

            let alreadyInitialised = false;
            if (!targetMeter) {
                alreadyInitialised = intialisedMeters.some((rc) => rc.endpoint === jackpot.url && rc.skinId === counterSkinMap[jackpot.skin]);
            }

            if (!targetMeter && !alreadyInitialised) {
                console.error(`Error: could not match endpoint to meter: ${jackpot.url}, no available id or skin`);
            }
        });

        // rollingCounter.forEach((rc, index) => {
        //     rc.getJackpot(props.jackpots[index].url);
        // });

        // remove special preload effects so that we can get on with it
        setTimeout(() => {
            counterWrapper.classList.remove('counter--wrapper__preload');
        }, 0);
    }, [rollingCounter, props.jackpots]);

    const path = props.path ? props.path : '';

    const counters = [];
    const counterSkinMap = { gold: 0, silver: 1, bronze: 2, yellowgold: 3, orange: 4, red: 5 }; // to init skins by number

    const rollDelayInSeconds = props.rollDelayInSeconds ? Math.max(props.rollDelayInSeconds, 1) : 5; // default is 5s
    const rollTransitionTime = 0.5; // in seconds
    const rollAnimStartPercent = ((rollDelayInSeconds - rollTransitionTime) / rollDelayInSeconds) * 100;

    const labelBaseSize = 4;

    return (
        <>
            <style>{`
        .counter--wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            transform-style: preserve-3d;
            margin-top: 1.25rem;
            margin-top: ${(labelBaseSize * 0.8) / 2 + labelBaseSize / 4}cqw;
            margin-bottom: 1.25rem;
            margin-bottom: ${(labelBaseSize * 0.8) / 2 + labelBaseSize / 4}cqw;
        }

        .counter--wrapper.counter--wrapper__animating-${discriminator} {
            animation: rotate-toggle-${discriminator} ${rollDelayInSeconds}s infinite cubic-bezier(0.68, -0.6, 0.32, 1.6);
            animation-fill-mode: forwards;
        }

        .counter--wrapper__preload {
        }
        .counter--wrapper > .counter--element__start,
        .counter--wrapper > .counter--element__end {
            top: 0;
            backface-visibility: hidden;
        }

        .counter--wrapper__preload > .counter--element {
            position: static;
            display: block;
        }

        .counter--element {
            position: absolute;
            display: none;
            object-fit: contain;
            font-size: 0;
            width: 100%;
        }

        .counter--element__start,
        .counter--element__end {
            display: block;
        }

        .counter--element__start {
            order: 0;
        }

        .counter--element__end {
            order: 1;
        }

        .counter--element__lower-label {
            position: relative;
        }

        .counter--element canvas {
            width: 100%;
            height: 100%;
        }

        .counter--label-wrapper {
            display: flex;
            justify-content: center;
            container: counter-label-wrapper / inline-size;
        }
        .counter--element__lower-label .counter--label-wrapper {
            position: absolute;
            bottom: 0;
            width: 100%;
            transform: translateY(100%);
        }

        .counter--label {
            display: inline;
            position: absolute;
            padding: 0.25rem 0.5rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1;
            margin: -0.25rem auto;
            background-color: #000000;
            background-image: linear-gradient(to top, #1F1F1F 49%, #545454 50%, #1F1F1F);
            color: #ffffff;
            border-radius: 0.375rem;
            text-transform: uppercase;
            font-family: Arial, sans-serif;
            letter-spacing: 0.1rem;
            font-size: 0.75rem;
            font-weight: bold;
            transform: translateY(-1.125rem);
            border: 2px solid #B89333;

            /* Modern Browser Overrides */
            font-size: ${labelBaseSize}cqw;
            padding: 0 ${labelBaseSize / 2}cqw;
            border-width: ${labelBaseSize / 8}cqw;
            transform: translateY(-${labelBaseSize * 0.8}cqw);
            max-width: calc(100% - ${labelBaseSize - labelBaseSize / 8}cqw); /* font size - 2*padding - 2*border */
            line-height: ${labelBaseSize * 1.5 - labelBaseSize / 8}cqw;
            height: ${labelBaseSize * 1.5}cqw;
        }

        .counter--element__lower-label .counter--label {
            transform: translateY(0);
        }

        .counter--label__silver {
            border-color: #919190;
        }

        .counter--label__bronze {
            border-color: #AA7657;
        }

        .counter--label__orange {
            border-color: #FF8A00;
        }
        .counter--label__red {
            border-color: #9D0000;
        }

        .counter--label__yellowgold {
            position: relative;
            border-color: #E1C166;
            background: none;
            border: 0;
            color: #1b1b1b;
            text-shadow: -1.5px -1.5px 0 #EBCA6E, 1.5px -1.5px 0 #EBCA6E, -1.5px 1.5px 0 #EBCA6E, 1.5px 1.5px 0 #EBCA6E, 0 0 2px #EBCA6E;
            text-shadow: 0 0 3px #EBCA6E;
            font-weight: 900;
            font-family: Verdana, Tahoma, Arial, sans-serif;
            // color: black;
            // background-color: #EDD986;
            // background-image: linear-gradient(to top, #E6C34E 49%, #EFE29F 50%, #EBCA6E);
        }
        @supports(-webkit-text-stroke: 3px white) {
            .counter--label__yellowgold {
                text-stroke: 4.5px #EBCA6E;
                -webkit-text-stroke: 4.5px #EBCA6E;
                text-shadow: none;
            }
        }
        .counter--label__yellowgold::after {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            padding: 0 ${labelBaseSize / 2}cqw;
            text-stroke: 0;
            -webkit-text-stroke: 0;
            text-shadow: none;
        }

        @keyframes fade {
            0% {
                opacity: 0.75;
            }
            100% {
                opacity 0.1;
            }
        }

        @keyframes rotate-toggle-${discriminator} {
            ${rollAnimStartPercent}% {
                transform: rotateX(0);
            }

            to {
                transform: rotateX(-90deg);
            }
        }
    `}</style>

            <div className='counter--wrapper counter--wrapper__preload'>
                {props.jackpots.map((jackpot, index) => (
                    <AnimateCanvas
                        key={index}
                        {...props}
                        script={`${path}${props.script}`}
                        createjsScript={`${path}${CREATEJS_LIB}`}
                        externalScripts={[...(props.externalScripts || []), `${path}libs/RollingCounter_Global.js`]}
                        sounds={true}
                        name={props.name}
                        label={jackpot.label}
                        skin={jackpot.skin}
                        cssClassName={`counter-${index} counter--element`}
                        onStageReady={(stage) => {
                            // console.log(`STAGE: ${stage}`);
                            const vcm = new VirtualCounterManager();
                            if (jackpot.url) {
                                vcm.getCounter(jackpot.url);
                            }

                            counters.push(
                                new RollingCounter(stage.children[0], {
                                    xOffset: 680.5,
                                    yOffset: 70,
                                    digits: 7,
                                    space: 125,
                                    height: -150,
                                    mask: {
                                        x: 9,
                                        y: 9,
                                        w: 715,
                                        h: 108,
                                    },
                                    skinId: counterSkinMap[jackpot.skin],
                                    symbol: { symbol: '$', symbolXOffset: 10, dollarXOffset: 20 },
                                    id: jackpot.id,
                                    debugId: props.debugId,
                                    manager: jackpot.url ? vcm : null,
                                })
                            );

                            if (counters.length === props.jackpots.length) {
                                setRollingCounter(counters);
                                return;
                            }
                        }}
                    />
                ))}
            </div>
        </>
    );
};

const refType = PropTypes.shape({ current: PropTypes.any });

RollingCount.propTypes = {
    ...AnimateCanvas.propTypes,

    path: PropTypes.string,
    script: PropTypes.string,
    name: PropTypes.string,

    digits: PropTypes.number,
    space: PropTypes.number,
    height: PropTypes.number,
    endpoint: PropTypes.string,
    value: PropTypes.number,
};

RollingCount.defaultProps = {
    script: 'RollingCounter.js',
    name: 'RollingCounter',
};

export default RollingCount;
