import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCMSObject, TeleScript } from 'sg-ui-components';
import { mapDispatchToProps, mapStateToProps } from '../Store';

/**********************************************************************
 * Component: PlayerPointsLimiters
 * Purpose:   Shows the users points in relation to the current
 *            point limiters for PFD and PFP for the timeperiod
 *
 * Props: -   points -  reference to the points stroe
 *            actions - actions table for api calls
 *
 * APIs used:  getPoints() - gets the updated points information with
 *                           limiters/
 */
const PlayerPointsLimiters = ({ points, actions, telescript }) => {
    const coinReference = getCMSObject('data.components.teleScripts.myPointsHistory.jsonBlock');

    const [hasPFPLimiter, setHasPFPLimiter] = useState(false);
    const [hasPFDLimiter, setHasPFDLimiter] = useState(false);
    const [pfpLimit, setPFPLimit] = useState('n/a');
    const [pfdLimit, setPFDLimit] = useState('n/a');
    const [pfpBalance, setPFPBalance] = useState('n/a');
    const [pfdBalance, setPFDBalance] = useState('n/a');
    const [pfpQuantum, setPFPQuantum] = useState('n/a');
    const [pfdQuantum, setPFDQuantum] = useState('n/a');
    const [isUserPFPLimited, setIsUserPFPLimited] = useState(false);
    const [isUserPFDLimited, setIsUserPFDLimited] = useState(false);

    //***********************************************************************************
    // Gets the Points Limiters and current user points.
    //
    const getPointsLimiters = async () => {
        await actions.pointActions.getPoints();
    };

    useEffect(() => {
        getPointsLimiters();
    }, []);

    //***********************************************************************************
    // Parse the points object to get the relevant data.   See what limiters are
    // currently running and see if the user has hit any of them.
    //
    useEffect(() => {
        if (points && points.totals) {
            const pfpInfo = points.totals.find((o) => o.name === 'pfp');
            const pfdInfo = points.totals.find((o) => o.name === 'pfd');
            if (pfpInfo && pfpInfo.limit && pfpInfo.limit.quantum != null) {
                setHasPFPLimiter(true);
                if (pfpInfo.limit.limit !== null) setPFPLimit(pfpInfo.limit.limit);
                if (pfpInfo.limit.value !== null) setPFPBalance(parseInt(pfpInfo.limit.value));
                if (pfpInfo.limit.quantum !== null) setPFPQuantum(pfpInfo.limit.quantum);
                if (pfpInfo.limit.limited) setIsUserPFPLimited(true);
            }
            if (pfdInfo && pfdInfo.limit && pfdInfo.limit.quantum != null) {
                setHasPFDLimiter(true);
                if (pfdInfo.limit.limit !== null) setPFDLimit(pfdInfo.limit.limit);
                if (pfdInfo.limit.value !== null) setPFDBalance(parseInt(pfdInfo.limit.value));
                if (pfdInfo.limit.quantum !== null) setPFDQuantum(pfdInfo.limit.quantum);
                if (pfdInfo.limit.limited) setIsUserPFDLimited(true);
            }
        }
    }, [points]);

    const LimiterInfo = ({ isLimited, limitType, limit, balance, quantum }) => {
        return (
            <div>
                {isLimited ? (
                    <p className='point-limit-info theme-danger'>
                        <TeleScript
                            line={telescript?.youHaveReachedYourLimit}
                            replace={{
                                '%LIMIT_TYPE%': limitType,
                                '%LIMIT%': limit,
                                '%BALANCE%': balance,
                                '%QUANTUM%': quantum,
                                '%REMAINING%': limit - balance,
                            }}>
                            You have reached your {quantum} {limitType} Limit
                        </TeleScript>
                    </p>
                ) : (
                    <p className='point-limit-info'>
                        <TeleScript
                            line={telescript?.youMayEarnMoreTowardsYourMaximumLimit}
                            replace={{
                                '%LIMIT_TYPE%': limitType,
                                '%LIMIT%': limit,
                                '%BALANCE%': balance,
                                '%QUANTUM%': quantum,
                                '%REMAINING%': limit - balance,
                            }}>
                            You may earn{' '}
                            <strong>
                                {limit - balance} more {limitType}
                            </strong>{' '}
                            towards your{' '}
                            <strong>
                                {limit} {quantum} maximum limit.
                            </strong>
                        </TeleScript>
                    </p>
                )}
            </div>
        );
    };

    return (
        <div>
            {hasPFPLimiter ? (
                <LimiterInfo
                    limitType={`${coinReference?.pointReference ?? ''} For Prizes`}
                    isLimited={isUserPFPLimited}
                    limit={pfpLimit}
                    balance={pfpBalance}
                    quantum={pfpQuantum}
                />
            ) : null}
            {hasPFDLimiter ? (
                <LimiterInfo
                    limitType={`${coinReference?.pointReference ?? ''} For Drawings`}
                    isLimited={isUserPFDLimited}
                    limit={pfdLimit}
                    balance={pfdBalance}
                    quantum={pfdQuantum}
                />
            ) : null}
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerPointsLimiters);
