import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Register = ({ user, config, cmsSourceFirebase, actions, match }) => {
    const registrationUrl = config?.config?.registration_host_uri ?? '';

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {user.loggedIn ? <Redirect to='/enter' /> : null}
            <div className='my-5 text-center'>
                <h3>
                    Redirecting to registration page:
                    <a href={registrationUrl}>{!user.loggedIn && registrationUrl ? (window.location.href = registrationUrl) : null}</a>
                </h3>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
