import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getCookieByName, isBrowser, getCMSObject, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const Redirect = ({ user, config, sweep, cmsSourceFirebase, actions, match }) => {
    const lotteryUrl = config?.config?.lottery_host ?? '';
    const redirectButton = useRef(null);

    const [pageLoaded, setPageLoaded] = useState(false);
    const [url, setUrl] = useState(lotteryUrl);
    const [authToken, setAuthToken] = useState('');

    const telescript = getCMSObject('data.components.teleScripts.ssoRedirect.jsonBlock');

    useEffect(() => {
        //* Retrieve ID from url params
        const queryString = isBrowser() ? window.location.search : '';
        const urlParams = new URLSearchParams(queryString);
        setUrl(urlParams.get('url'));

        async function getPlayerData() {
            await actions.userActions.getPlayerData();
            setPageLoaded(true);
        }
        getPlayerData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            if (user?.loggedIn) {
                await fetchApiData();
            }
        }

        fetchData();
    }, [user?.loggedIn]);

    useEffect(() => {
        if (pageLoaded && url) {
            if (redirectButton.current) {
                if (authToken) {
                    redirectButton?.current?.click();
                } else {
                    window.location.replace(url);
                }
            }
        }
    });

    const postData = async (url = '', headers = {}, data = {}) => {
        if (typeof window !== 'undefined') {
            // Create the XHR request
            var request = new XMLHttpRequest();

            // Return it as a Promise
            return await new Promise(function (resolve) {
                // Setup our listener to process compeleted requests
                request.onreadystatechange = function () {
                    // Only run if the request is complete
                    if (request.readyState !== 4) return;

                    // Process the response
                    resolve(request.response ? JSON.parse(request.response) : {});
                };

                // Setup our HTTP request
                request.open('POST', url, true);
                request.setRequestHeader('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');
                request.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
                request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
                for (const [key, value] of Object.entries(headers)) {
                    request.setRequestHeader(key, value);
                }
                request.withCredentials = true;
                // Send the request
                request.send(JSON.stringify(data));
            });
        }
    };

    const fetchApiData = async () => {
        const generateRedirectTokenUrl = '/sso/generate-redirect-token';
        const AuthTokenCookie = JSON.parse(getCookieByName('AuthToken')) ?? {};
        const response = await postData(generateRedirectTokenUrl, { 'X-Auth-Spat': AuthTokenCookie?.spat }, {});

        if (response?.auth_token) {
            setAuthToken(response?.auth_token);
        }
    };

    return (
        <Layout {...{ user, sweep, config, cmsSourceFirebase, actions, match }}>
            <div className='my-5 text-center'>
                <h3>
                    <TeleScript line={telescript?.redirectingToTheLotterySite}>Redirecting to the Lottery site:</TeleScript>
                    <br />
                    <a href={url}>{url}</a>
                </h3>
            </div>
            <div className='text-center'>
                <form id='auth_form' action={`${lotteryUrl}PlayersClub/SCELSecondChance`} method='post'>
                    <input id='auth_token' name='auth_token' type='hidden' value={authToken} />
                    <input id='redir' name='redir' type='hidden' value={url} />
                    <input
                        id='submit'
                        name='submit'
                        type='submit'
                        ref={redirectButton}
                        className='d-none'
                        value={telescript?.redirectButton ?? 'Click to proceed to South Carolina Education Lottery site'}
                    />
                </form>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
