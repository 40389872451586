import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    HomeEnterTickets,
    NextDrawings,
    CurrentSweepstakes,
    CurrentPromotions,
    getCMSObject,
    formatDateTBD,
    ErrorBoundary,
    TeleScript,
    DisplayContent,
} from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import UserLockout from '../utils/user_lockout';
import EnterTicketTabs from '../Components/Enter/EnterTicketTabs';
import ProgressiveJackpot from '../Components/ProgressiveJackpot/ProgressiveJackpot';
import promotionConfig from '../promotionConfig';
import PointsForDrawingsSection from '../Components/PointsForDrawings/PointsForDrawings';

const Home = ({ user, config, sweep, winner, promotion, cmsSourceFirebase, actions, pointsForDrawings, match }) => {
    const urlParams = new URLSearchParams(window.location.search);

    const telescriptHomeEnterTickets = getCMSObject('data.components.teleScripts.homeEnterTickets.jsonBlock');
    const telescriptNextDrawing = getCMSObject('data.components.teleScripts.nextDrawing.jsonBlock');
    const telescriptHomeSweepstakes = getCMSObject('data.components.teleScripts.homePromotions.jsonBlock');
    const telescriptHomePromotions = getCMSObject('data.components.teleScripts.myRewardsHighlights.jsonBlock');
    const isPointsForDrawingsEnabled = getCMSObject('data.features.pointsForDrawings', 'webConfig');

    useEffect(() => {
        if (!user.loggedIn && urlParams.get('Hash')) {
            const ptaURL = window.location.protocol + '//' + window.location.host + '/login-pta' + window.location.search;
            window.location.href = ptaURL;
        }
    }, [user?.loggedIn]);

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <div className='row justify-content-center'>
                <div className='col-lg-6 entry-box' id='home-enter-ticket-container'>
                    <ErrorBoundary fallback={<TeleScript line={telescriptHomeEnterTickets?.fallback} />} details={{ user, config }}>
                        <HomeEnterTickets user={user} telescript={telescriptHomeEnterTickets ?? ''}>
                            <UserLockout>
                                <EnterTicketTabs />
                            </UserLockout>
                        </HomeEnterTickets>
                    </ErrorBoundary>
                </div>
                <div className='col-lg-6' id='home-next-drawing-container'>
                    <ErrorBoundary fallback={<TeleScript line={telescriptNextDrawing?.fallback} />} details={{ user, config }}>
                        <NextDrawings
                            {...{ user, winner, cmsSourceFirebase, actions }}
                            formatDate={formatDateTBD?.dateWithDayOfTheWeek}
                            telescript={telescriptNextDrawing ?? ''}
                            options={{
                                variant: 'theme-primary',
                                limitOnePerSweepstake: promotionConfig?.nextDrawingsLimitOnePerSweepstake ?? true,
                                maxElement: promotionConfig?.nextDrawingsMaxElement ?? 5,
                            }}
                        />
                    </ErrorBoundary>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <ErrorBoundary fallback={<TeleScript line={telescriptHomeSweepstakes?.fallback} />} details={{ user, config }}>
                        <CurrentSweepstakes
                            {...{ sweep, cmsSourceFirebase, actions }}
                            telescript={telescriptHomeSweepstakes}
                            mode='top3_with_show_more'
                            promotionLink='/promotions#second-chance-promotions'
                            ProgressiveJackpot={ProgressiveJackpot}
                            options={{ variant: 'theme-primary' }}
                        />
                    </ErrorBoundary>
                </div>
            </div>
            <DisplayContent isVisible={isPointsForDrawingsEnabled}>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <PointsForDrawingsSection {...{ actions, pointsForDrawings }} />
                    </div>
                </div>
            </DisplayContent>
            <div className='row justify-content-center'>
                <div className='col-12' id='home-reward-highlights-container'>
                    <ErrorBoundary fallback={<TeleScript line={telescriptHomePromotions?.fallback} />} details={{ user, config }}>
                        <CurrentPromotions
                            {...{ user, config, promotion, cmsSourceFirebase, actions }}
                            telescript={telescriptHomePromotions}
                            mode='show-limited-external-url'
                            options={{
                                hasCollapser: false,
                                showNoLimit: true,
                                variant: 'theme-primary',
                                jurisdiction: promotionConfig?.jurisdiction,
                                promotionsLink: '/promotions#achievements',
                            }}
                        />
                    </ErrorBoundary>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
