import React from 'react';
import { getCMSObject } from 'sg-ui-components';

import { RollingCount } from '../Vendor/ProgressiveJackpot';
import DoubleMeters from './DoubleMeters';

const ProgressiveJackpot = ({ jackpots = [], sweepstake = {} }) => {
    const debugId = (Math.random() + 1).toString(36).substring(7);

    //? For debug: console.log('🔥 ProgressiveJackpot sweep', debugId, sweepstake);

    // Validation: input need to have valid jackpot names
    if ((Array.isArray(jackpots) && jackpots.length > 0) === false) return null;

    //* Check: special meters for Clemson
    if (jackpots.includes('Clemson')) {
        return <DoubleMeters debugId={debugId} sweepstake={sweepstake} />;
    }

    // Validation: CMS need to have valid jackpot names in the config
    const jackpotConfirmationTable = getCMSObject('data.components.progressiveJackpots.jsonBlock') ?? [];
    if ((Array.isArray(jackpotConfirmationTable) && jackpotConfirmationTable.length > 0) === false) return null;

    // Validation: filtered jackpot table also needs to have elements
    const filteredJackpots = jackpots.filter((jackpot) => jackpotConfirmationTable.find((element) => element.name == jackpot));
    if ((Array.isArray(filteredJackpots) && filteredJackpots.length > 0) === false) return null;

    const jackpotsMapping = filteredJackpots.map((jackpot) => jackpotConfirmationTable.find((element) => element.name == jackpot));
    if ((Array.isArray(jackpotsMapping) && jackpotsMapping.length > 0) === false) return null;

    return (
        <div className='progressive-jackpot-element position-absolute'>
            <RollingCount debugId={debugId} path='/animations/progressive-jackpot/' jackpots={jackpotsMapping} />
            {/* For debug: <h3>{ debugId }</h3> */}
        </div>
    );
};

export default ProgressiveJackpot;
