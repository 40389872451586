import React from 'react';
import { getCMSObject } from 'sg-ui-components';

import { DoubleJackpot } from '../Vendor/ProgressiveJackpot';

// eslint-disable-next-line no-unused-vars
const DoubleMeters = ({ debugId = '', sweepstake = {} }) => {
    const carolinaClemsonData = getCMSObject('data.components.carolinaClemson.jsonBlock') ?? {};

    let jackpots = carolinaClemsonData.jackpots ?? [];
    const drawingGroupNames = carolinaClemsonData.drawingGroupNames ?? [];

    if (jackpots.length === 2 && drawingGroupNames.length === 2) {
        const firstEntryCount = sweepstake?.drawing_groups?.filter((group) => group?.name === drawingGroupNames[0])?.[0]?.entry_count ?? 0;
        const secondEntryCount = sweepstake?.drawing_groups?.filter((group) => group?.name === drawingGroupNames[1])?.[0]?.entry_count ?? 0;
        jackpots[0].value = firstEntryCount;
        jackpots[1].value = secondEntryCount;
    }

    return (
        <div className='progressive-jackpot-element-double-meters position-absolute'>
            <DoubleJackpot path='/animations/double-meters/' jackpots={jackpots} />
            {/* For debug: <h3>{ debugId }</h3> */}
        </div>
    );
};

export default DoubleMeters;
