import React from 'react';
import { connect } from 'react-redux';
import { Carousel } from 'react-bootstrap';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import ProgressiveJackpot from '../Components/ProgressiveJackpot/ProgressiveJackpot';

const ProgressiveJackpotPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    //? for testing: const ProgressiveJackpot = () => <></>;

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <h1 className='my-5'>Progressive Jackpot test page</h1>

            <h2>Sweepstake image example</h2>

            <div className='row'>
                <div className='col-4'>
                    <div className='progressive-jackpot-container-sweepstake position-relative'>
                        <ProgressiveJackpot jackpots={['Clemson']} />
                        <img className='card-img-top img-fluid' src='https://dlxq6hk38h98r.cloudfront.net/sc/sweepstake_4_content_128_image.png' />
                    </div>
                </div>
            </div>

            <hr />

            <h2>Banner carousel example</h2>

            <div className='hero-carousel d-xs-block'>
                <div data-testid='carousel-inner' className='overflow-hidden carousel slide'>
                    <ol className='carousel-indicators'>
                        <li className='active'></li>
                    </ol>
                    <div className='carousel-inner'>
                        <div data-testid='carousel-inner-item-0' className='text-center active carousel-item'>
                            <div className='progressive-jackpot-container-banner position-relative'>
                                <ProgressiveJackpot jackpots={['Demo', 'Demo2']} />
                                <a href='https://scl-qa03-webapp.sg-bonusing.com/second-chance-promotion?id=9' target='_self' rel='noreferrer'>
                                    <img className='img-fluid' src='https://dlxq6hk38h98r.cloudfront.net/sc/banner_1_image_1_url.jpg' alt='Home - Image 1' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <a className='carousel-control-prev' role='button' href='#'>
                        <span aria-hidden='true' className='carousel-control-prev-icon'></span>
                        <span className='sr-only'>Previous</span>
                    </a>
                    <a className='carousel-control-next' role='button' href='#'>
                        <span aria-hidden='true' className='carousel-control-next-icon'></span>
                        <span className='sr-only'>Next</span>
                    </a>
                </div>
            </div>

            <Carousel onSelect={(k) => console.log('🎃 Carousel onSelect', k)} data-testid='carousel-inner' className='overflow-hidden'>
                <Carousel.Item data-testid={`carousel-inner-item-0`} key={0} className='text-center'>
                    <a href='#' target='_self' rel='noreferrer'>
                        <img className='img-fluid' src='https://dlxq6hk38h98r.cloudfront.net/sc/banner_1_image_1_url.jpg' alt='Home - Image 1' />
                    </a>
                </Carousel.Item>
                <Carousel.Item data-testid={`carousel-inner-item-1`} key={1} className='text-center'>
                    <div className='progressive-jackpot-container-banner position-relative'>
                        <ProgressiveJackpot jackpots={['Demo', 'Demo2']} />
                        <a href='#' target='_self' rel='noreferrer'>
                            <img className='img-fluid' src='https://dlxq6hk38h98r.cloudfront.net/sc/banner_1_image_1_url.jpg' alt='Home - Image 1' />
                        </a>
                    </div>
                </Carousel.Item>
            </Carousel>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressiveJackpotPage);
