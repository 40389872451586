import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isBrowser, Coupon, PostClaimBox, TeleScript, getCMSObject, SetupRevealGames, InProgressPromotionsDisplay, ErrorMessage } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import UserLockout from '../../utils/user_lockout';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PlayerPointsLimiters from '../PlayerPointsLimiters';
import AllocatableEntriesForm from '../AllocatableEntries/AllocatableEntriesForm';
import siteConfig from '../../promotionConfig';
import pluralize from 'pluralize';
import { titleCase } from 'title-case';
import { HashLink } from 'react-router-hash-link';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FaExclamationCircle } from 'react-icons/fa';
import Scanner from '../Enter/Scanner';

import './style.scss';

library.add(FaExclamationCircle);

/**********************************************************************
 * Component: PostClaim
 * Purpose:   Shows rewards from Bonusing, orders them by type and
 *            sets up any reveal games for the user to play as well
 *            as displaying limiter information.
 *
 * Props: -   config - site configuration information
 *            revealToken -  API token for the game server
 *            actions - actions table for api calls
 *            bonusing -  reference to the bonusing store
 *            loading - reference to the loading store
 *
 * APIs used:  getRevealToken() - gets the reveal gameserver token
 */
export const PostClaim = ({ user, config, revealToken, actions, bonusing, loading }) => {
    const [ticketError, setTicketError] = useState(null);
    const [noRewardError, setNoRewardError] = useState(false);
    const [lastActionInfo, setLastActionInfo] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const motive = isBrowser() ? location?.state?.motive : '';

    // To do -  eventually remove this imgURL as this is the OLD way to get images from bonusing.   The
    // newer way is to grab the content obect and the URLs will be in there.   However, MO (and perhaps other)
    // older implementations of ensemble use this older approach.
    //
    const imgUrl =
        (config.config.image_host && (config.config.image_host.startsWith('https://') ? config.config.image_host : `https://${config.config.image_host}`)) ||
        '';
    const telescript = getCMSObject('data.components.teleScripts.postClaimMessaging.jsonBlock');
    const telescriptPlayerPointsLimiters = getCMSObject('data.components.teleScripts.playerPointsLimiters.jsonBlock');

    useEffect(() => {
        async function fetchRevealToken() {
            if (!revealToken.length) {
                await actions.revealTokenActions.getRevealToken();
            }
            await actions.userActions.getPlayerDataFromAuthToken();
        }

        fetchRevealToken();

        const updateDashboard = async () => {
            if (user.loggedIn && user.player.actions.length === 0) {
                await actions.userActions.getDashboard();
            }
        };

        updateDashboard();

        // for Testing if Ticket Services is down, you can just test Post Claim with stubbed data
        // via the Bonusing Store and useing the sample BAR object.
        /* async function fetchDetails() {
            await actions.bonusingActions.getBarObject();
        }

        fetchDetails();
        */
        // end for testing
        setPageLoaded(true);

        /************************************************************************************************************************
         * Motives are passed in via the Redirect parameters to Post Claim to tell the component what action causes us to
         * reach the post-claim page (took a survey, clicked social media, entered ticket, etc).   Based upon the motive, the
         * page can display different links and messaging to thank the user for their action and guide the user to the next
         * thing to do (enter another ticket, go home, take another survey, etc). The motives can be customized via
         * the CMS.   As More features get added to Ensemble, this list of motives and options should grow.
         *
         */
        let motiveInfo = {
            linkTo: '',
            linkText: '',
            congratulationsMessage: '',
            action: '',
            moreInfoLink: '',
            moreInfoText: '',
            triggerType: '',
        };
        const juristictionName = typeof siteConfig.jurisdictionName === 'string' ? siteConfig.jurisdictionName : '';
        const postClaimMotives = getCMSObject('data.components.teleScripts.postClaimMotives.jsonBlock');

        // Might be more Motives later,  still a requirements TBD
        switch (motive) {
            case 'facebook':
                motiveInfo.linkTo = postClaimMotives?.facebookLinkTo ?? '/home';
                motiveInfo.linkText = postClaimMotives?.facebookLinkText ?? 'Go Home';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.facebookCongratulationsMessage ?? 'Thank you for Liking ' + juristictionName + ' Lottery on Facebook.';
                motiveInfo.triggerType = postClaimMotives?.facebookTriggerType ?? 'Like on Facebook';
                break;

            case 'twitter':
                motiveInfo.linkTo = postClaimMotives?.twitterLinkTo ?? '/home';
                motiveInfo.linkText = postClaimMotives?.twitterLinkText ?? 'Go Home';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.twitterCongratulationsMessage ?? 'Thank you for following ' + juristictionName + ' Lottery on Twitter.';
                motiveInfo.triggerType = postClaimMotives?.twitterTriggerType ?? 'Follow on Twitter';
                break;

            case 'youtube':
                motiveInfo.linkTo = postClaimMotives?.youtubeLinkTo ?? '/home';
                motiveInfo.linkText = postClaimMotives?.youtubeLinkText ?? 'Go Home';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.youtubeCongratulationsMessage ?? 'Thank you for following ' + juristictionName + ' Lottery on YouTube.';
                motiveInfo.triggerType = postClaimMotives?.youtubeTriggerType ?? 'Follow on YouTube';
                break;

            case 'instagram':
                motiveInfo.linkTo = postClaimMotives?.instagramLinkTo ?? '/home';
                motiveInfo.linkText = postClaimMotives?.instagramLinkText ?? 'Go Home';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.instagramCongratulationsMessage ?? 'Thank you for following ' + juristictionName + ' Lottery on Instagram.';
                motiveInfo.triggerType = postClaimMotives?.instagramTriggerType ?? 'Follow on Instagram';
                break;

            case 'Survey':
                motiveInfo.linkTo = postClaimMotives?.surveyLinkTo ?? '/home';
                motiveInfo.linkText = postClaimMotives?.surveyLinkText ?? 'Go Home';
                motiveInfo.congratulationsMessage = postClaimMotives?.surveyCongratulationsMessage ?? 'Thank you for completing our survey.';
                motiveInfo.triggerType = postClaimMotives?.surveyTriggerType ?? 'Survey';
                break;

            case 'TicketEntry':
                motiveInfo.linkTo = postClaimMotives?.ticketEntruLinkTo ?? '/enter/';
                motiveInfo.linkText = postClaimMotives?.ticketEntryLinkText ?? 'Enter Another Ticket';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.ticketEntryCongratulationsMessage ?? 'Congratulations! <br> Your Ticket Has Been' + ' Accepted!';
                motiveInfo.action = postClaimMotives?.ticketEntryTriggerType ?? 'entry';
                break;

            case 'ScanTicket':
                motiveInfo.linkTo = postClaimMotives?.scanEntryLinkTo ?? 'scanner';
                motiveInfo.linkText = postClaimMotives?.scanEntryLinkText ?? 'Enter Another Ticket';
                motiveInfo.congratulationsMessage =
                    postClaimMotives?.scanEntryCongratulationsMessage ?? 'Congratulations! <br> Your Ticket Has Been' + ' Accepted!';
                motiveInfo.action = postClaimMotives?.scanTicketTriggerType ?? 'entry';
                break;

            default:
                motiveInfo.linkTo = postClaimMotives?.defaultLinkTo ?? '/enter/';
                motiveInfo.linkText = postClaimMotives?.defaultLinkText ?? 'Enter Another Ticket';
                motiveInfo.congratulationsMessage = postClaimMotives?.defaultCongratulationsMessage ?? '';
                motiveInfo.action = postClaimMotives?.defaultTriggerType ?? 'entry';
        }
        setLastActionInfo(motiveInfo);

        return () => {
            actions.bonusingActions.reset();
            setPageLoaded(false);
            setTicketError(null);
            setNoRewardError(false);
            setLastActionInfo({});
        };
    }, []);

    useEffect(() => {
        // Check if User Won anything
        if (bonusing.error) {
            setTicketError(bonusing.error);
        } else if (
            bonusing.awardedPromotions &&
            bonusing.inProgressPromotions &&
            bonusing.awardedPromotions.length === 0 &&
            bonusing.inProgressPromotions.length === 0
        ) {
            setNoRewardError(true);
        }
    }, [bonusing]);

    //***********************************************************************************
    // Cleans up store data and send user to destination based on how they got their
    // rewards.
    const onLinkBack = (e) => {
        e.preventDefault();
        actions.entryActions.reset();
        actions.drawTicketActions.reset();
        actions.scratchTicketActions.reset();
        actions.bonusingActions.reset();
        history.push(lastActionInfo.linkTo);
    };

    //***********************************************************************************
    // Component for a Allocated Entries Sweepstake Award.
    //
    const AllocatableRewardsDisplay = () => {
        return (
            <div className='container-fluid'>
                {bonusing.allocatableRewards.map((rewardDisplay, index) => {
                    const legacyImage = imgUrl + '/' + siteConfig.jurisdiction + '/promotion_' + rewardDisplay.promotionId + '_thumb.png';
                    return (
                        <div key={index}>
                            <PostClaimBox
                                imageUrl={rewardDisplay.content?.thumb ?? legacyImage}
                                name={rewardDisplay.promotionName ?? 'prize'}
                                currency={rewardDisplay.promoReward?.reward?.currency ?? ''}
                                prize_name={rewardDisplay.promoReward?.reward?.prize_name ?? ''}
                                intent_amount={rewardDisplay.promoReward?.reward?.intent_amount ?? 0}
                                actual_amount={rewardDisplay.promoReward?.reward?.actual_amount ?? 0}
                                multiplier={rewardDisplay.promoReward?.reward?.multiplier ?? 1}
                                isAllocatable={true}
                            />
                            <AllocatableEntriesForm
                                sweepId={rewardDisplay.promoReward?.reward.sku ?? ''}
                                promotionName={rewardDisplay.promotionName ?? ''}
                                numEntries={rewardDisplay.promoReward?.reward?.actual_amount ?? ''}
                                entryId={rewardDisplay.promoReward?.reward?.entry_id ?? ''}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }; // end AllocatableRewardsDisplay

    //***********************************************************************************
    // Component for a rewarded item.   Shows the promotion image with what they won
    // and a link (if applicable) for more details.
    //
    const RewardsDisplay = ({ rewardsArray }) => {
        return (
            <div className='container-fluid'>
                {rewardsArray.map((rewardDisplay, index) => {
                    let promotionError = '';
                    const legacyImage = imgUrl + '/' + siteConfig.jurisdiction + '/promotion_' + rewardDisplay.promotionId + '_thumb.png';
                    if (rewardDisplay?.promoReward?.reward?.state === 'failed') {
                        promotionError = telescript?.rewardPendingMsg ?? 'Reward pending. Please check back later.';
                    } else if (rewardDisplay?.promoReward?.reward?.actual_amount < rewardDisplay.promoReward.reward.intent_amount) {
                        promotionError =
                            rewardDisplay?.promoReward?.reward?.actual_amount == 0
                                ? telescript?.rewardNotFulfilledMsg ?? 'Your reward could not be fulfilled at this time.'
                                : telescript?.rewardPartialFilledMsg ?? 'Your reward could only be partially awarded at this time.';
                    }
                    return (
                        <PostClaimBox
                            key={index}
                            imageUrl={rewardDisplay.content?.thumb ?? legacyImage}
                            name={rewardDisplay.promotionName ?? ''}
                            currency={rewardDisplay.promoReward?.reward?.currency ?? ''}
                            prize_name={rewardDisplay.promoReward?.reward?.prize_name ?? ''}
                            intent_amount={rewardDisplay.promoReward?.reward?.intent_amount ?? 0}
                            actual_amount={rewardDisplay.promoReward?.reward?.actual_amount ?? 0}
                            multiplier={rewardDisplay.promoReward?.reward?.multiplier ?? 1}
                            promotionError={promotionError ?? ''}
                        />
                    );
                })}
            </div>
        );
    }; // end PointRewards

    //***********************************************************************************
    // Component for a coupon rewards.   Shows the promotion image with what they won
    // and a button that will popup the coupon details (which then can be printed ).   A
    // link is also displayed that will take the user to their full coupon history.
    //
    const CouponsDisplay = () => {
        return (
            <div className='container-fluid'>
                {bonusing.couponRewards.map((rewardDisplay, index) => {
                    let promotionError = '';
                    const image =
                        rewardDisplay.content?.thumb ?? imgUrl + '/' + siteConfig.jurisdiction + '/promotion_' + rewardDisplay.promotionId + '_thumb.png';
                    if (rewardDisplay.promoReward.reward.state === 'failed') {
                        promotionError = telescript?.rewardPendingMsg ?? 'Reward pending. Please check back later.';
                    } else if (rewardDisplay.promoReward.reward.actual_amount < rewardDisplay.promoReward.reward.intent_amount) {
                        promotionError =
                            rewardDisplay.promoReward.reward.actual_amount == 0
                                ? telescript?.rewardNotFulfilledMsg ?? 'Your reward could not be fulfilled at this time'
                                : telescript?.rewardPartialFilledMsg ?? 'Your reward could only be partially awarded at this time';
                    }
                    return (
                        <div key={index}>
                            <div className='media my-2 text-center claim-box' key={rewardDisplay.promotionName ?? ''}>
                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-6 px-0'>
                                            {image && <img className='promo-image' src={`${image}`} alt={`${rewardDisplay.promotionName ?? ''} logo`} />}
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='media-body promotion-info-container'>
                                                <h2 className='promotion-name'>{rewardDisplay.promotionName ?? ''}</h2>
                                                {promotionError ? <p className='mt-0 error'>{promotionError}</p> : null}
                                                <p className='mt-1 reward-amount'>{rewardDisplay.promoReward?.reward?.actual_amount ?? ''}</p>
                                                <p className='mt-1 reward-currency'>
                                                    {pluralize(
                                                        titleCase(rewardDisplay.promoReward?.reward?.description ?? ''),
                                                        rewardDisplay.promoReward?.reward?.actual_amount ?? '',
                                                        false
                                                    )}
                                                </p>
                                                <HashLink to='/my-activity#coupons-history'>View Coupon History</HashLink>
                                                <Coupon
                                                    className='Coupon'
                                                    lottery_logo={getCMSObject('data.sitewideSettings.alternateLogo.image.imageSrc')}
                                                    reward_id={rewardDisplay.promoReward.reward.reward_id.toString() ?? index.toString()}
                                                    prize_name={rewardDisplay.promoReward.reward.display_name ?? rewardDisplay.promoReward.reward.prize_name}
                                                    prize_description={rewardDisplay.promoReward.reward.prize_description ?? ''}
                                                    redeem={rewardDisplay.promoReward.reward.redeem ?? ''}
                                                    expiration={rewardDisplay.promoReward.reward.expiration.toString() ?? ''}
                                                    state={rewardDisplay.promoReward.reward.state ?? ''}
                                                    Url={rewardDisplay.promoReward.reward.Url ?? ''}
                                                    barcodeHost={config.config.barcode_image_host ?? ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    //***********************************************************************************
    // Component for a button that takes the user back to where they came from that caused
    // the reward to fire
    //
    const LinkBack = () => {
        if (lastActionInfo.linkTo === 'scanner') {
            return <Scanner buttonText={lastActionInfo.linkText} />;
        } else {
            return (
                <Link to={lastActionInfo.linkTo} className='link-back-btn' onClick={(e) => onLinkBack(e)}>
                    {lastActionInfo.linkText}
                </Link>
            );
        }
    };

    //***********************************************************************************
    // Congratulations/Success message for the action that caused the reward.
    //
    const CongratulationsMessage = () => {
        return (
            <>
                <h1 className='congratulation-message' dangerouslySetInnerHTML={{ __html: lastActionInfo.congratulationsMessage }} />
                {/* only show a congratulations for promos in progress or awarded */}
                {bonusing.awardedPromotions.length > 0 || bonusing.promosInProgress.length > 0 ? (
                    <TeleScript line={telescript?.congratulationsMsg}>
                        <h3 className='your-earned-message'>You have earned:</h3>
                    </TeleScript>
                ) : (
                    <TeleScript line={telescript?.noRewardsWonMsg}>
                        <p>
                            You have not earned any new rewards at this time, please check the current list of{' '}
                            <Link to='/promotions'>My Promotions & Rewards</Link> for opportunities to win.
                        </p>
                    </TeleScript>
                )}
            </>
        );
    };

    if (
        !pageLoaded ||
        loading.actions['allocateEntries'] ||
        loading.actions['getSweeps'] ||
        loading.actions['getActiveSweeps'] ||
        loading.actions['enterDrawTicket'] ||
        loading.actions['enterScratchTicket']
    ) {
        return null;
    } else {
        return (
            <UserLockout currentSection='post-claim' disableRedirect='survey'>
                <div className='rewards-area'>
                    {ticketError ? (
                        <div className='row m-1'>
                            <div className='col text-center '>
                                <p className='my-3 error-box'>There was an error with your ticket: </p>
                                <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />
                                <p className='text-center'>
                                    <LinkBack />
                                </p>
                            </div>
                        </div>
                    ) : (
                        <>
                            {noRewardError ? (
                                <div className='row m-1'>
                                    <div className='col text-center'>
                                        <p className='my-3'>
                                            <FaExclamationCircle className='error-tooltip' /> Your {lastActionInfo.triggerType} Does Not Qualify For Any Current
                                            Promotions Or Reward
                                        </p>
                                        <TeleScript line={telescript?.actionNotQualifiedMsg}>
                                            <p>
                                                Check the <Link to='/promotions'>My Rewards</Link> list to see what actions qualify.
                                            </p>
                                            <p className='text-center'>
                                                <LinkBack />
                                            </p>
                                        </TeleScript>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className='row'>
                                        <div className='col px-md-5 text-center'>
                                            <CongratulationsMessage />
                                        </div>
                                    </div>
                                    <SetupRevealGames bonusing={bonusing} revealToken={revealToken} config={config} />

                                    {/* Allocatable entries rewards should always be first shown */}

                                    <AllocatableRewardsDisplay />
                                    <RewardsDisplay rewardsArray={bonusing.experientialRewards} />
                                    <RewardsDisplay rewardsArray={bonusing.sweepRewards} />
                                    <CouponsDisplay />
                                    <RewardsDisplay rewardsArray={bonusing.promoCodeRewards} />
                                    <RewardsDisplay rewardsArray={bonusing.pointRewards} />
                                    <InProgressPromotionsDisplay
                                        promosInProgress={bonusing.promosInProgress}
                                        siteConfg={siteConfig}
                                        legacyImageDomain={imgUrl}
                                    />

                                    <div className='row'>
                                        <div className='col text-center my-4'>
                                            <PlayerPointsLimiters telescript={telescriptPlayerPointsLimiters} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='mx-auto px-4 my-4 text-center'>
                                            <p className='my-4 '>
                                                <TeleScript line={telescript?.footerForMoreInformation}>
                                                    For more information about your entry, view <HashLink to='/my-activity'>My Activity</HashLink>.
                                                </TeleScript>
                                            </p>
                                            <LinkBack />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </UserLockout>
        ); // end return
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PostClaim);
