import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import * as yup from 'yup';

import { ContentBox, ContentBoxHead, ContentBoxBody, getCMSObject, TeleScript, AlertBox } from 'sg-ui-components';
import { HashLink } from 'react-router-hash-link';

import { FaRegTimesCircle } from 'react-icons/fa';
import { Alert } from 'react-bootstrap';

const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

const ForgotPassword = ({ user, actions }) => {
    const initialStepFields = {
        email: '',
    };

    const schema = yup.object().shape({
        email: yup.string().email('Email is invalid').required('Email is required').max(200, 'Email cannot exceed 200 characters'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'forgot-password', status: 'initial' });

            const payloadData = {
                email: stepFields.email,
            };

            await actions.userActions.forgotPassword(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    return (
        <div className='login-container my-5'>
            <form className='theme-form'>
                <ContentBox variant='theme-blue'>
                    <ContentBoxHead>
                        <TeleScript line={loginTelescript?.titleForgotMyPassword}>FORGOT MY PASSWORD</TeleScript>
                    </ContentBoxHead>
                    <ContentBoxBody>
                        <div className='form-step'>
                            <div className='inner-step'>
                                {user?.updateSection?.section === 'forgot-password' && user?.updateSection?.status === 'success' ? (
                                    <>
                                        <AlertBox
                                            variant='success'
                                            message={
                                                loginTelescript?.emailHasBeenSent ??
                                                'An email has been sent. Please follow the link in your email to reset your password.'
                                            }
                                        />
                                        <div className='col-12'>
                                            <TeleScript line={loginTelescript?.passwordForgotSuccessTest}>
                                                <p>
                                                    It can take up to 15 minutes to receive your password reset email. You may need to check your spam folder.
                                                </p>
                                                <p>
                                                    If you did nor receive the email you can resend or <HashLink to='/feedback#contact'>Contact Us</HashLink>{' '}
                                                    for Help
                                                </p>
                                            </TeleScript>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='enterEmail'>Email</label>
                                                <input
                                                    type='email'
                                                    className='form-control theme-input'
                                                    autoComplete='off'
                                                    id='enterEmail'
                                                    value={stepFields.email}
                                                    name='email'
                                                    onChange={handleChange}
                                                    placeholder={loginTelescript?.emailPlaceholder ?? 'Enter email'}
                                                />
                                                <small>username@domain.com</small>
                                            </div>
                                        </div>

                                        <div className='row justify-content-center'>
                                            <div>
                                                <button type='button' className='resend-email-btn' onClick={handleSubmit}>
                                                    <TeleScript line={loginTelescript?.passwordForgotResendBtnText}>Resend Email</TeleScript>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {error ? (
                                            <Alert variant='danger'>
                                                <FaRegTimesCircle />
                                                <ul className='alert-text'>
                                                    {Object.values(localErrors).map((err, index) => {
                                                        return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                                    })}
                                                </ul>
                                            </Alert>
                                        ) : null}

                                        {user?.updateSection?.section === 'forgot-password' && user?.updateSection?.status === 'error' && (
                                            <AlertBox message={loginTelescript?.emailDoesNotBelong ?? 'This email does not belong to any users.'} />
                                        )}

                                        <div className='col-12'>
                                            <TeleScript line={loginTelescript?.passwordForgotTest}>
                                                <p>
                                                    Forgot your password? Enter your email address and we&apos;ll send you a link to reset your password if we
                                                    find a matching account.
                                                </p>
                                            </TeleScript>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label htmlFor='enterEmail'>Email</label>
                                                <input
                                                    type='email'
                                                    className='form-control theme-input'
                                                    autoComplete='off'
                                                    id='enterEmail'
                                                    value={stepFields.email}
                                                    name='email'
                                                    onChange={handleChange}
                                                    placeholder={loginTelescript?.emailPlaceholder ?? 'Enter email'}
                                                />
                                                <small>username@domain.com</small>
                                            </div>
                                        </div>

                                        <div className='row justify-content-center'>
                                            <div>
                                                <button type='button' className='forgot-password-submit-btn' onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </form>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
