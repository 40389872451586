import React, { useEffect, useState, useRef } from 'react';
import { RollingCount } from '../';

const DoubleJackpot = ({ jackpots = [], path = 'publish' }) => {
    // Validation: input need to have valid jackpot names
    if ((Array.isArray(jackpots) && jackpots.length == 2) === false) return null;

    // eslint-disable-next-line no-unused-vars
    const [componentHeight, setComponentHeight] = useState({}); // height of the djc container
    const djcRef = useRef(null); // ref to the double jackpot container

    const sizeDoubleJackpot = () => {
        if (!djcRef.current) return;
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            sizeDoubleJackpot();
        });
        sizeDoubleJackpot();
    }, [djcRef]);

    return (
        <>
            <style>{`

            .double-jackpot-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 0.5rem;
            }

            .double-jackpot-container .counter--element {
                position: static;
            }
            .double-jackpot-container .counter--wrapper {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

    `}</style>
            <div>
                <div className='double-jackpot-container' ref={djcRef} style={componentHeight}>
                    <RollingCount path={path} responsive='none' scale='none' rollDelayInSeconds='5' jackpots={[jackpots[0]]} />
                    <RollingCount path={path} responsive='none' scale='none' rollDelayInSeconds='5' jackpots={[jackpots[1]]} />
                </div>
            </div>
        </>
    );
};

export default DoubleJackpot;
