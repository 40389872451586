// import { VirtualCounterManager } from "./VirtualCounterManager";

export class VirtualCounter {
    /**
     * The endpoint this counter hits for data
     */
    _endpoint = null;

    /**
     * The RequestAnimationFrame ID for clearing purposes
     */
    _rafID = null;

    /**
     * HTML Status of Endpoint
     */
    _endpointStatus = null;

    /**
     * Units in seconds the counter increments
     */
    _rate = null;

    /**
     * Round trip time to server
     */
    _latency = null;

    /**
     * units in seconds the animation can move at before
     * flipping to a rolling counter instead of instant.
     * Lower than this is a rolling animation. higher
     * is instant
     */
    MIN_ANIMATED_RATE = 0.5;

    /**
     * The time in seconds that the counter starts progressing
     * through the units
     */
    _startTime = null;

    /**
     * The time in seconds that the counter starts progressing
     * through the units
     */
    _endTime = null;

    /**
     * The starting amount for the counter. The amount the counter will be at at startTime
     */
    _startJackpot = null;

    /**
     * The ending amount for the counter. The amount the counter will be at at endTime
     */
    _endJackpot = null;

    /**
     * Returns when the jackpot session will expire
     * so VC can rehit the API
     */
    get jackpotSessionExpired() {
        return Date.now() >= this._endTime - this._latency;
    }

    /**
     * Return the current state of the virtual counter
     */
    get current() {
        // guard against no api response
        if (this._endpointStatus < 200 || this._endpointStatus >= 400) {
            return { current: 0, next: 0, progress: 0 };
        }

        // calc time progress overall
        const time = Date.now() - this._startTime;
        const duration = this._endTime - this._startTime;
        const progress = this.Clamp(time / duration, 0, 1);

        // calc progress from one money unit to the next money unit
        // diff between float and int is progress to next char
        const currentValue = this.Lerp(this._startJackpot, this._endJackpot, progress);
        const currentInt = Math.floor(currentValue);
        const currentProgress = currentValue - currentInt;

        // potential flaw to always assume next int is +1
        const nextInt = currentInt + 1;

        // choose easing func based on how fast were going.
        const easingFunc = this._rate > this.MIN_ANIMATED_RATE ? this.EasInOutInstant : this.EaseInOutQuart;

        // build state obj
        const state = {
            current: currentInt,
            next: nextInt,
            progress: easingFunc(currentProgress),
        };

        // return!
        return state;
    }

    /**
     * @param _endpoint: string
     */
    constructor(_endpoint) {
        this._endpoint = _endpoint;
        this.getJackpot();
        this.start();
    }

    /**
     * Kicks off the raf
     */
    start() {
        this.update();
    }

    /**
     * stops the raf
     */
    stop() {
        window.clearTimeout(this._rafID);
    }

    /**
     * One Timestep
     * @param dt{number} deltatime in x
     */
    update() {
        if (this.jackpotSessionExpired) {
            this.getJackpot();
        }

        this._rafID = window.requestAnimationFrame(this.update.bind(this));
    }

    /**
     * Hit the API, and initialize the counter's state
     */
    getJackpot() {
        const requestTimestamp = Date.now(); // 1000;

        // does last checkedjackpot exist
        // has it been too soon since last check
        const tooSoon = requestTimestamp - this.checkedJackpotAt < 100;
        if (this.checkedJackpotAt && tooSoon) return;

        this.checkedJackpotAt = requestTimestamp;

        this._apiRequest();
    }

    /**
     * Make API request, passing data to handleResponse()
     */
    _apiRequest() {
        const request = new XMLHttpRequest();

        request.open('GET', this._endpoint, true);

        request.onload = () => {
            const data = JSON.parse(request.response);
            this._endpointStatus = parseInt(request.status);

            this._handleResponse(data, this._endpointStatus);
        };
        request.send();
    }

    /**
     * Heavy lifting function taking API data and initializing
     * counter's internal state
     */
    _handleResponse = (data, status) => {
        const standardize = function (first, second) {
            return first != undefined ? first : second != undefined ? second : 0;
        };

        const nowInMillis = Date.now(); // 1000;

        // guard against bad statuses
        if (!(status >= 200 && status < 400)) return;

        // standardize based on which API is hit
        data.start = standardize(data.start, data.quantum_start);
        data.end = standardize(data.end, data.quantum_end);
        data.total = standardize(data.total, data.jackpot_amount);
        data.base = standardize(data.base, data.base_amount);

        // adapt for server units
        // / 1000 for sever seconds
        // / 100 for pennies to dollars
        data.start = data.start * 1000;
        data.end = data.end * 1000;
        data.total = Math.floor(data.total / 100);
        data.base = Math.floor(data.base / 100);

        // track some time stuff
        this._latency = nowInMillis - this.checkedJackpotAt;
        this.timeSinceRequest = this._latency;

        // console.log(`${this._endpoint} will expire in  ${data.end - data.start - this._latency}ms`)
        // setTimeout(() => {
        //   console.log(`JACKPOT DATA HAS EXPIRED ${this._endpoint}`);
        // }, data.end - data.start - this._latency);

        // validate that code
        this.validate(data);

        this._startTime = nowInMillis;
        this._endTime = Math.floor(this._startTime) + Math.max(data.end - data.start, 100);

        const progress = (nowInMillis - this.checkedJackpotAt) / (data.end - data.start);
        const realBaseValue = this.Lerp(data.base, data.total, progress);
        var correctedJackpotAmount = this.Clamp(realBaseValue, 0, data.total);

        correctedJackpotAmount = Math.floor(correctedJackpotAmount);
        this._rate = data.total == data.base ? 0 : ((data.total - data.base) / (data.end - data.start)) * 1000;

        this._startJackpot = correctedJackpotAmount;
        this._endJackpot = data.total;
    };

    /**
     * Perform error checking, mostly related to digit length vs
     * available counter spaces
     */
    validate(data, digits) {
        const totalString = data.total.toString().padStart(digits, '0');
        const baseString = data.base.toString().padStart(digits, '0');

        if (data.start > data.end) {
            console.error(`Error ${this._endpoint}: end time cannot be before start time`);
        }

        if (data.base > data.total) {
            console.error(`Error ${this._endpoint}: base amount cannot be greater than total amount`);
        }

        if (totalString.length > digits) {
            console.error(`Error ${this._endpoint}: total amount is too large to fit within the ${digits} digits limit. Total string is: ${totalString}`);
        }

        if (baseString.length > digits) {
            console.error(`Error ${this._endpoint}: base amount is too large to fit within the ${digits} digits limit. Base string is: ${baseString}`);
        }
    }

    EasInOutInstant(x) {
        return x < 0.5 ? 0 : 1;
    }

    EaseInOutQuad(x) {
        return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
    }

    EaseInOutQuart(x) {
        return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
    }

    Clamp(value, min, max) {
        return Math.min(Math.max(min, value), max);
    }

    Lerp(initialValue, finalValue, progress) {
        progress = this.Clamp(progress, 0, 1);
        return initialValue + (finalValue - initialValue) * progress;
    }
}
