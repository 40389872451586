import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { ContentBox, ContentBoxHead, ContentBoxBody, getCMSObject, PasswordField, ErrorMessage } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

const HandlerContext = React.createContext(() => {});
const ErrorContext = React.createContext(() => {});

export const LoginFormTemplate = ({ user, actions, children }) => {
    const initialState = {
        email: '',
        password: '',
        terms_check: '',
        pta_version: 'v3',
    };

    useEffect(() => {
        async function clearErrors() {
            await actions.userActions.clearErrors();
        }

        clearErrors();
    }, []);

    const schema = yup.object().shape({
        email: yup.string().email('invalid_email').required('email_required'),
        password: yup
            .string()
            .matches(/^(?!\s+$).*/, 'password_required')
            .required('password_required'),
    });

    const [state, setState] = useState(initialState);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(state);

        if (valid) {
            await actions.userActions.clearErrors();
            await actions.userActions.login(state);
        } else {
            await schema.validate(state, { abortEarly: false }).catch(function (err) {
                actions.userActions.setErrors({
                    errors: getValidationErrors(err),
                });
            });
        }
    };

    const getValidationErrors = (err) => {
        let validationError = '';

        if (err.inner[0].path) {
            validationError = err.inner[0].message;
        }

        return validationError;
    };

    const formUrl = '/users/login.php';

    return (
        <div>
            {user.errors === '_TOO_MANY_TRIES' || user.errors === '_LOGIN_LOCKED' ? (
                <ContentBox variant='theme-blue'>
                    <ContentBoxHead>Login</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='row justify-content-center my-3'>
                            <div className='col-12 col-md-8'>
                                <Alert variant='danger' className='d-block'>
                                    <p className='alert-text'>
                                        Account locked. Too many login attempts. Try again in one hour or contact us for support on the issue.
                                    </p>
                                    <p className='text-center mt-3 mb-0'>
                                        <HashLink to='/feedback#contact' className='btn btn-lg theme-btn theme-danger'>
                                            Contact Us
                                        </HashLink>
                                    </p>
                                </Alert>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            ) : (
                <div className='login-form mt-3'>
                    <div id='login-target'>
                        <form
                            name='player_login'
                            id='player_login'
                            className='theme-form'
                            action={formUrl}
                            method='post'
                            encType='application/x-www-form-urlencoded'
                            onSubmit={handleLogin}>
                            <input type='hidden' id='form_submitted' name='form_submitted' value='1' />
                            <HandlerContext.Provider value={handleChange}>
                                <ErrorContext.Provider
                                    value={{
                                        code: user.errors,
                                        data: user.errorMessage,
                                    }}>
                                    {children}
                                </ErrorContext.Provider>
                            </HandlerContext.Provider>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

const LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginFormTemplate);

const LoginErrorCard = () => {
    const errors = useContext(ErrorContext);
    return <ErrorMessage code={errors.code} collection='data.messages.errorMessages.jsonBlock' />;
};

const LoginEmail = () => {
    const handler = useContext(HandlerContext);
    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

    return (
        <div className='form-group'>
            <label htmlFor='email'>Email:</label>
            <input
                type='text'
                id='email'
                name='email'
                required={true}
                onChange={handler}
                placeholder={loginTelescript?.emailPlaceholder ?? 'Enter Email'}
                className='form-control theme-input'
            />
            <small>username@domain.com</small>
        </div>
    );
};

const LoginPassword = () => {
    const handler = useContext(HandlerContext);

    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');
    return (
        <div className='form-group'>
            <label htmlFor='password'>Password:</label>
            <PasswordField name='password' placeholder={loginTelescript?.passwordPlaceholder ?? 'Enter Password'} handleChange={handler} />
            <small>
                <Link to='/forgot-password'>Forgot password</Link>
            </small>
        </div>
    );
};

const LoginSubmit = ({ loading }) => {
    return (
        <div>
            <input type='submit' name='submit' value='Submit' disabled={loading?.actions?.['login']} className='btn theme-btn theme-primary btn-block' />
        </div>
    );
};

export { LoginForm, LoginErrorCard, LoginEmail, LoginPassword, LoginSubmit };
