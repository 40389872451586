module.exports = {
    routes: {
        '': {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        home: {
            title: 'Home',
            pageHeader: 'Home',
            className: 'home',
        },
        login: {
            title: 'Login in',
            className: 'login-template',
        },
        postclaim: {
            title: 'Enter Tickets',
            className: 'post-claim',
        },
        logout: {
            title: 'Logout',
            className: 'logout',
        },
        drawings: {
            title: 'Redeem Coins',
            pageHeader: 'Redeem Coins',
            className: 'pointsForDrawings',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Redeem Coins', path: '/drawings' },
            ],
        },
        secondchancepromotion: {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'second-chance-promotion',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#second-chance-promotions' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
            path: 'promotion',
        },
        promotion: {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'promotion-details',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions#achievements' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
            path: 'promotion',
        },
        'pointsfordrawingdetails:sweepId': {
            title: '{{PROMOTION_NAME}}',
            pageHeader: '{{PROMOTION_NAME}}',
            className: 'points-for-drawing-details',
            feature: 'pointsForDrawings',
            featureDisabledRedirectPath: '/home',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Redeem Coins', path: '/drawings' },
                { name: '{{PROMOTION_NAME}}', path: '' },
            ],
        },
        progressivejackpottest: {
            title: 'Progressive Jackpot Test',
            className: 'progressive-jackpot',
        },
        promotions: {
            title: 'Promotions & Achievements',
            pageHeader: 'Promotions & Achievements',
            className: 'promotions',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Promotions', path: '/promotions' },
            ],
        },
        feedback: {
            title: 'Help',
            pageHeader: 'Help',
            className: 'feedback',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Help', path: '/feedback' },
            ],
        },
        myprofile: {
            title: 'My Profile',
            pageHeader: 'My Profile',
            className: 'my-profile',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Profile', path: '/my-profile' },
            ],
            userLockout: true,
        },
        survey: {
            title: 'Survey',
            pageHeader: 'Survey',
            className: 'survey',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Survey', path: '/survey' },
            ],
            userLockout: true,
        },
        enter: {
            title: 'Ticket Entry',
            pageHeader: 'Ticket Entry',
            className: 'enter',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Ticket Entry', path: '/enter' },
            ],
            userLockout: true,
        },
        winners: {
            title: 'Drawings & Winners',
            pageHeader: 'Drawings & Winners',
            className: 'winners',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Drawings & Winners', path: '/winners' },
            ],
        },
        myactivity: {
            title: 'My Activity',
            pageHeader: 'My Activity',

            className: 'my-activity',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'My Activity', path: '/my-activity' },
            ],
            userLockout: true,
        },
        referafriend: {
            title: 'Refer a Friend',
            pageHeader: 'Refer a Friend',
            className: 'refer-a-friend',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Refer a Friend', path: '/refer-a-friend' },
            ],
            userLockout: true,
        },
        register: {
            title: 'Registration',
            pageHeader: 'Registration',
            className: 'register',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Registration', path: '/register' },
            ],
        },
        accountconfirm: {
            title: 'Account Confirm',
            pageHeader: 'Account Confirm',
            className: 'account-confirm',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Account Confirm', path: '/account-confirm' },
            ],
        },
        awaitaccountconfirmation: {
            title: 'Account Confirmation',
            className: 'await-account-confirmation',
        },
        forgotpassword: {
            title: 'Forgot Password',
            pageHeader: 'Forgot Password',
            className: 'forgot-password',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Forgot Password', path: '/forgot-password' },
            ],
        },
        recoverpassword: {
            title: 'Recover Password',
            pageHeader: 'Recover Password',
            className: 'recover-password',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Recover Password', path: '/recover-password' },
            ],
        },
        redirect: {
            title: 'Redirect',
            pageHeader: 'Redirect',
            className: 'redirect',
            breadcrumb: [
                { name: 'Home', path: '/' },
                { name: 'Redirect', path: '/redirect' },
            ],
        },
    },
    title: 'SCEL Ensemble',
    seoDefaultTitle: 'South Carolina Education Lottery',
    promotion_id: '',
    jurisdiction: 'sc',
    imgFolderName: 'sc',
    promotion: 'ensemble_site',
    appUrl: '',
    lottoUrl: '',
    prodhost: '',
    uathost: '',
    feedbackIssueZone: 'SCBZ',
    defaultLoginLink: `/login`,
    defaultRegisterLink: '/register',
    getLoginLink: (app, redirect) => (app && redirect ? `` : this.defaultLoginLink),
    getRegisterLink: (app, redirect) => (app && redirect ? `` : this.defaultRegisterLink),
    getLogoutDestination: (hostURL, ptaLogout) => (ptaLogout !== undefined ? ptaLogout : hostURL + '/home'),
    forgot: '/users/forgot',
    dashboardComponents: ['pfd'],
    testing: {},
    modules: {
        hasWallet: false,
    },
    components: {
        nextDrawings: {
            limitOnePerSweepstake: true,
            maxElement: 5,
        },
        recentDrawings: {
            defaultPageSize: 10,
        },
        rewardsHistory: {
            defaultPageSize: 5,
        },
        drawingEntries: {
            defaultPageSize: 5,
        },
        ticketHistory: {
            defaultPageSize: 5,
        },
        pointHistory: {
            defaultPageSize: 5,
        },
        pointsForDrawingsDetailsView: {
            loginUrl: '/login',
            registerUrl: '/register',
            defaultNumEntries: 1,
        },
    },
    resolveCurrency: (name) => {
        const currencyExchange = [
            {
                name: 'sweepstake',
                displayName: 'sweepstake',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
            {
                name: 'lotteryproducts',
                displayName: 'lotteryproducts',
                showQuantity: false,
                isPence: false,
                resolve: (amount) => {
                    return '';
                },
            },
            {
                name: 'promocash',
                displayName: 'promocash',
                showQuantity: true,
                isPence: true,
                resolve: (amount) => {
                    const amt = amount / 100;
                    return new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(amt);
                },
            },
            {
                name: 'default',
                displayName: 'default',
                showQuantity: true,
                isPence: false,
                resolve: (amount) => {
                    return amount;
                },
            },
        ];

        return currencyExchange.filter((item) => {
            return item.name === name || item.name === 'default';
        })[0];
    },

    /*
    All draw games: 0000-000000000-000000 (4-9-6 digits)
    */
    drawTicketLength: 19,
    drawFields: [
        { name: 'field1', placeholder: '0000', size: '4', value: '' },
        { name: 'field2', placeholder: '000000000', size: '9', value: '' },
        { name: 'field3', placeholder: '000000', size: '6', value: '' },
    ],
    propsDrawFieldInputs: {
        pattern: '[0-9]*',
        inputmode: 'numeric',
    },
    badTicketInputReplacement: /[o,O]/g, // Regex to remove bad input from ticket fields.
    scratchTicketLengthRegex: '^[0-9]{25}$',
    scratchTicketLength: 25,
    scratchTicketFields: {
        gameNumberFields: [
            {
                name: 'gameNum',
                placeholder: 'GGGG',
                size: '4',
                value: '',
                dataindex: '0',
                side: 'back',
            },
        ],
        fieldGroups: [
            {
                validRanges: [['0000', '9999']],
                frontNumberFields: [
                    {
                        name: 'validation',
                        placeholder: 'VVVVVVVVVVV',
                        size: '11',
                        value: '',
                        dataindex: '5',
                        side: 'front',
                    },
                ],
                backNumberFields: [
                    {
                        name: 'packNum',
                        placeholder: 'BBBBBB',
                        size: '6',
                        value: '',
                        dataindex: '1',
                        side: 'back',
                    },
                    {
                        name: 'checkDigit',
                        placeholder: 'X',
                        size: '1',
                        value: '',
                        dataindex: '2',
                        side: 'back',
                    },
                    {
                        name: 'ticketNum',
                        placeholder: 'TTT',
                        size: '3',
                        value: '',
                        dataindex: '3',
                        side: 'back',
                    },
                ],
            },
        ],
    },
    propsScratchFieldInput: {
        pattern: '[0-9]*',
        inputmode: 'numeric',
    },
    banners: {
        homeCarousel: 'scl_home_page_carousel',
        promotionCarousel: 'scl_promo_page_carousel',
    },
    apiClientConfig: {
        appUrl: '',
        routes: {
            getConfig: 'ctlEnsemble.getConfig',
            login: 'sclEnsemble.login',
            logout: 'ctlEnsemble.logout',
            checkTicket: 'mrbEnsemble.checkTicket',
            enterIGT24Ticket: 'ctlEnsemble.enterTicket',
            enterDrawTicket: 'mrbEnsemble.enterTicket',
            enterScratchTicket: 'mrbEnsemble.enterTicket',
            enterFastPlayTicket: 'mrbEnsemble.enterTicket',
            getEntries: 'scbzApi.getEntries',
            getPlayerData: 'loyalty2.getPlayerData',
            setEntryFlashGameFlag: 'scbzApi.setEntryFlashGameFlag',
            update: 'ctlEnsemble.update',
            register: 'mrbEnsemble.register',
            verify: 'ctlEnsemble.verify',
            confirm: 'ctlEnsemble.confirm',
            confirmResend: 'sclEnsemble.confirmResend',
            winners: 'ctlEnsemble.winners',
            postFeedback: 'ctlEnsemble.postFeedback',
            getFeedbackCategories: 'ctlEnsemble.getFeedbackCategories',
            forgotPassword: 'ctlEnsemble.forgotPassword',
            recoverPassword: 'ctlEnsemble.recoverPassword',
            getGame: 'fauxScbzApi.getGame',
            getGameOver: 'fauxScbzApi.getGameOver',
            getAchievements: 'ctlEnsemble.getAchievements',
            getPoints: 'ctlEnsemble.getPoints',
            getActivity: 'ctlEnsemble.getActivity',
            getReward: 'ctlEnsemble.getReward',
            getCoupons: 'mrbEnsemble.getCoupons',
            getPromotions: 'ctlEnsemble.getPromotions',
            getPointsForDrawings: 'loyalty2.getPointsForDrawings',
            getPlayerPFDTransactions: 'loyalty2.getPlayerPFDTransactions',
            redeemPFDPoints: 'loyalty2.redeemPFDPoints',
            getPlayerPromotions: 'ctlEnsemble.getPlayerPromotions',
            getSweeps: 'ctlEnsemble.getSweeps',
            getSweepDetails: 'mrbEnsemble.getSweepDetails',
            allocateEntries: 'mrbEnsemble.allocateEntries',
            getActiveSweeps: 'ctlEnsemble.getActiveSweeps',
            getCurrentSweeps: 'ctlEnsemble.getActiveSweeps',
            getExpiredSweeps: 'ctlEnsemble.getExpiredSweeps',
            getRevealToken: 'ctlEnsemble.getRevealToken',
            getBanner: 'ctlEnsemble.getBanner',
            expiredDrawings: 'ctlEnsemble.recentDrawings',
            currentDrawings: 'ctlEnsemble.currentDrawings',
            nextDrawings: 'ctlEnsemble.nextDrawings',
            getAllOptins: 'mrbEnsemble.getAllOptins',
            getOptins: 'mrbEnsemble.getOptins',
            optinSubscribe: 'mrbEnsemble.optinSubscribe',
            optinUnsubscribe: 'mrbEnsemble.optinUnsubscribe',
            sendRafInvite: 'mrbEnsemble.sendRafInvite',
            getRafCode: 'mrbEnsemble.getRafCode',
            getActiveSurveys: 'mrbEnsemble.getActiveSurveys',
            getSurveyStatus: 'mrbEnsemble.getSurveyStatus',
            getSurveyQuestions: 'mrbEnsemble.getSurveyQuestions',
            getNamedSurveyQuestions: 'mrbEnsemble.getNamedSurveyQuestions',
            setSurveyAnswer: 'mrbEnsemble.setSurveyAnswer',
            completeSurvey: 'mrbEnsemble.completeSurvey',
            getDashboard: 'mrbEnsemble.getDashboard',
            submitSocialMediaClick: 'mrbEnsemble.submitSocialMediaClick',
            getPlayerAllocatableEntries: 'mrbEnsemble.getPlayerAllocatableEntries',
            getPointsHistory: 'mrbEnsemble.getPointsHistory',
            actionComplete: 'mrbEnsemble.actionComplete',
            callExternalEndpoint: 'mrbEnsemble.callExternalEndpoint',
            getThirdPartyPrizes: 'mrbEnsemble.getThirdPartyPrizes',
            getThirdPartyPrizeBarcode: 'mrbEnsemble.getThirdPartyPrizeBarcode',
            getTicketActivity: 'mrbEnsemble.getTicketActivity',
        },
    },
};
