import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCMSObject, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { LoginForm, LoginErrorCard, LoginEmail, LoginPassword, LoginSubmit } from '../Components/Login/login_form';
import siteConfig from '../promotionConfig';

/**********************************************************************
 * Component:  LoginPageTemplate
 * Purpose:    Handles Login and redirects user to various places
 *             based on the player action table for PAM based juristcitions.
 *
 * Props:       user -  user data store
 *              actions - store actions (apis)
 *              config - configuration store
 *              cmsSourceFirebase - CMS configuration
 *
 * APIs used:   None
 *
 *  Notes:
 */
const Login = ({ user, loading, config, cmsSourceFirebase, actions, match }) => {
    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

    //***********************************************************************************
    // Updates the dashboard on a ticket entry, see if points have been updated
    //
    const updateDashboard = async () => {
        await actions.userActions.getDashboard();
    };

    //? Redirection to an external partner or an internal page after a successful login
    if (user.loggedIn && config?.config?.lottery_host) {
        const redirect = new URLSearchParams(document.location.search).get('redirect');
        const partner = new URLSearchParams(document.location.search).get('partner');
        if (user.loggedIn && partner === 'evelopers' && redirect && config.config.lottery_host) {
            window.location.href = config.config.lottery_host + '/' + redirect;
            return null;
        } else if (user.loggedIn && partner === 'store' && redirect && config.config.store_uri) {
            window.location.href = config.config.store_uri + '/' + redirect;
            return null;
        } else {
            updateDashboard();
            return <Redirect to={redirect || siteConfig?.successfulLoginRedirect} />;
        }
    }

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <LoginForm>
                <div className='row justify-content-center'>
                    <div className='login-box'>
                        <div className='content-box theme-blue'>
                            <div className='loginbox-title content-box-head'>Login</div>
                            <div className='loginbox content-box-body'>
                                <div className='col-12'>
                                    <LoginErrorCard />
                                </div>
                                <div className='col-12'>
                                    <LoginEmail />
                                </div>
                                <div className='col-12'>
                                    <LoginPassword />
                                </div>
                                <div className='row justify-content-center'>
                                    <div>
                                        <LoginSubmit loading={loading} />
                                    </div>
                                </div>
                                <div className='row justify-content-center mt-3'>
                                    <p>
                                        <Link to='/register'>
                                            <TeleScript line={loginTelescript?.registerLinkText}>Don&apos;t have an account? Register Now</TeleScript>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoginForm>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
