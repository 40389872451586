import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import { isBrowser, AlertBox } from 'sg-ui-components';
import { ReadyToGo } from './ReadyToGo';

import * as yup from 'yup';

//* Retrieve ID from url params
const queryString = isBrowser() ? window.location.search : '';
const urlParams = new URLSearchParams(queryString);
const queryParams = {
    passcode: urlParams.get('passcode'),
};

const AccountConfirmSCEL = ({ user, actions }) => {
    const [verifying, setVerifying] = useState(true);
    const [email, setEmail] = useState('');
    const [emailValidationError, setEmailValidationError] = useState('');
    const [confirmationEmailResent, setConfirmationEmailResent] = useState(false);

    const handleChangeEmail = (event) => {
        setEmail(event.target.value.trim());
    };

    const verifyUser = async (params) => {
        if (params.passcode) {
            await actions.userActions.confirm({ passcode: params.passcode });
            setVerifying(false);
        }
    };

    useEffect(() => {
        verifyUser(queryParams);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const schema = yup.string().email('Email is invalid').required('Email is required').max(200, 'Email cannot exceed 200 characters');

        let valid = await schema.isValid(email);

        if (valid) {
            setEmailValidationError('');
            await actions.userActions.confirmResend({
                email,
            });
            setConfirmationEmailResent(true);
        } else {
            await schema.validate(email, { abortEarly: false }).catch(function (err) {
                setEmailValidationError(err.message);
            });
        }
    };

    if (!queryParams.passcode) {
        return <AlertBox message={'Missing validation token.'} />;
    }

    if (verifying) {
        return <div className='text-center py-3 h5'>Verifying...</div>;
    }

    if ('confirmResend' === user.lastServerResponse.type) {
        return (
            <div className='py-3'>
                <p className='px-2 text-center'>
                    {user?.lastServerResponse?.message?.message || 'Confirmation email resent. Please check email and verify link.'}
                </p>
                <p className='text-center form-step'>
                    <Link to='/forgot-password' className='resend-confirmation-email-button mt-3'>
                        Forgot password
                    </Link>
                </p>
            </div>
        );
    }

    const messageCode = user.lastServerResponse.message.code; // Successful responses don't have codes
    if (messageCode) {
        if (messageCode === '_ALREADY_CONFIRMED') {
            return <ReadyToGo />;
        }
        if (messageCode === '_EXPIRED_CONFIRMATION') {
            return (
                <>
                    <AlertBox message={user?.lastServerResponse?.message?.message} />
                    {confirmationEmailResent ? (
                        <div>Confirmation email resent. Please check email and verify link.</div>
                    ) : (
                        <>
                            <div className='col-12 form-step'>
                                <div className='form-group'>
                                    <AlertBox message={emailValidationError} />
                                    <label htmlFor='enterEmail' className='label-class'>
                                        Email
                                    </label>
                                    <input
                                        type='email'
                                        className='form-control theme-input'
                                        autoComplete='off'
                                        id='enterEmail'
                                        value={email}
                                        name='email'
                                        onChange={handleChangeEmail}
                                        placeholder={'Enter email'}
                                    />
                                    <small>username@domain.com</small>
                                </div>
                            </div>
                            <p className='text-center form-step'>
                                <button type='button' className='resend-confirmation-email-button mt-3 w-50' onClick={handleSubmit}>
                                    Resend Confirmation Email
                                </button>
                            </p>
                        </>
                    )}
                </>
            );
        }
        return <AlertBox message={user?.lastServerResponse?.message?.message} />;
    }

    return <ReadyToGo />;
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirmSCEL);
