import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, CurrentSweepstakes, CurrentPromotions, DisplayContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import PromotionBanner from '../Components/PromotionBanner';
import ProgressiveJackpot from '../Components/ProgressiveJackpot/ProgressiveJackpot';
import { jurisdiction } from '../promotionConfig';
import PointsForDrawingsSection from '../Components/PointsForDrawings/PointsForDrawings';

const Promotions = ({ user, config, sweep, promotion, cmsSourceFirebase, actions, match, pointsForDrawings }) => {
    const telescriptCurrentSweepstakes = getCMSObject('data.components.teleScripts.currentPromotions.jsonBlock');
    const telescriptCurrentPromotions = getCMSObject('data.components.teleScripts.myRewardsHighlights.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <section id='second-chance-promotions'>
                <CurrentSweepstakes
                    {...{ sweep, cmsSourceFirebase, actions }}
                    telescript={telescriptCurrentSweepstakes}
                    ProgressiveJackpot={ProgressiveJackpot}
                    options={{ hasCollapser: false, variant: 'theme-primary' }}
                />
            </section>
            <DisplayContent isVisible={config?.web?.features?.pointsForDrawings}>
                <section id='points-for-drawings'>
                    <PointsForDrawingsSection {...{ actions, pointsForDrawings }} />
                </section>
            </DisplayContent>
            <section id='achievements'>
                <CurrentPromotions
                    {...{ user, config, promotion, cmsSourceFirebase, actions }}
                    telescript={telescriptCurrentPromotions}
                    mode='show-all'
                    options={{
                        hasCollapser: false,
                        showNoLimit: true,
                        variant: 'theme-primary',
                        jurisdiction: jurisdiction,
                    }}
                />
            </section>

            <PromotionBanner />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
