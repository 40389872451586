import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const TermsPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHTML') && (
                <React.Fragment key='Terms of Use section'>
                    <BoxedContent
                        note='Terms section'
                        hash='terms'
                        isActive={true}
                        hasCollapser={false}
                        isVisible={hasCMSContent('data.legalAndPolicyItems.termsOfUse.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.termsOfUse.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.termsOfUse.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />

                        <React.Fragment key='Accept Terms of Use section'>
                            {user?.player?.actions?.includes('tos') ? (
                                <div className='d-flex justify-content-center'>
                                    <input
                                        type='submit'
                                        name='submit'
                                        value='Accept Terms of Use'
                                        className='accept-tos-btn'
                                        onClick={() => actions.userActions.actionComplete({ action_name: 'tos' })}
                                    />
                                </div>
                            ) : null}
                        </React.Fragment>
                    </BoxedContent>
                </React.Fragment>
            )}
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
