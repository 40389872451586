import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import TicketEntryBlurb from './TicketEntryBlurb';
import TicketSamples from './TicketSamples';
import UserLockout from '../../utils/user_lockout';
import EnterTicketTabs from './EnterTicketTabs';
import { Redirect } from 'react-router-dom';

const EnterTicketsPage = ({ actions }) => {
    // If we have a prepopulated ticket from the scanner, call the Enter Draw Ticket API and go directly to Post Claim
    // Post Claim will handle all the error reporting to the user if there is any.
    const prepopTicket = new URLSearchParams(document.location.search).get('ticketnumber');

    const [scannedTicketSubmitted, setScannedTicketSubmitted] = useState(false);

    const enterDrawTicketFromTicketNumberInUrl = useCallback(async (prepopTicket) => {
        if (prepopTicket) {
            await actions.drawTicketActions.enterDrawTicket({
                ticket_number: prepopTicket,
                entry_method: 'scan',
            });

            setScannedTicketSubmitted(true);
        }
    }, []);

    useEffect(() => {
        enterDrawTicketFromTicketNumberInUrl(prepopTicket);
    }, [prepopTicket]);

    if (scannedTicketSubmitted) {
        return (
            <Redirect
                to={{
                    pathname: '/post-claim',
                    state: { motive: 'ScanTicket' },
                    motive: 'ScanTicket',
                }}
            />
        );
    } else {
        return (
            <UserLockout>
                <div className='enter-container container'>
                    <div className='row justify-content-center'>
                        <div className='entry-box col-12 col-lg-6'>
                            <EnterTicketTabs />
                        </div>
                        <div className='entry-box col-12 col-lg-6'>
                            <TicketEntryBlurb />
                        </div>
                    </div>
                    <TicketSamples />
                </div>
            </UserLockout>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterTicketsPage);
