//? Note: this can be moved to sg-ui-components in the future but we need to make it configurable (10 characters, 8 characters, etc.)

import * as yup from 'yup';
import siteConfig from './promotionConfig';

// Password validation: for error messages
export const validatePassword = yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be 8 characters minimum')
    .matches(/([a-z])/, 'Your password does not match the criteria.')
    .matches(/([A-Z])/, 'Your password does not match the criteria.')
    .matches(/([0-9])/, ' Your password does not match the criteria.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your password does not match the criteria.');

// New Password: for error messages
export const validateNewPassword = yup
    .string()
    .required('New password is required')
    .min(8, 'New password should be 8 characters minimum')
    .matches(/([a-z])/, 'Your new password does not match the criteria.')
    .matches(/([A-Z])/, 'Your new password does not match the criteria.')
    .matches(/([0-9])/, ' Your new password does not match the criteria.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your new password does not match the criteria.');

// Detailed information for password fields
export const validatePasswordDetailed = yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be 8 characters minimum')
    .matches(/([a-z])/, 'Your password should contain at least one lowercase letter.')
    .matches(/([A-Z])/, 'Your password should contain at least one uppercase letter.')
    .matches(/([0-9])/, 'Your password should contain at least one number.')
    .matches(/([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/, 'Your password should contain at least one special character: !@#$%^&*');

// Draw Games: for Error Messages
export const validateDrawEntry = yup
    .string()
    .required('Please completely fill out all of the entry fields.')
    .min(siteConfig.drawTicketLength, 'Please completely fill out all of the entry fields.');

// Draw Games: for Error Messages
export const validateScratcherEntry = yup
    .string()
    .required('Please completely fill out all of the entry fields.')
    .min(siteConfig.scratchTicketLength, 'Please completely fill out all of the entry fields.')
    .matches(/^\d+$/, 'Scratch-Off game fields should be numbers only.');
