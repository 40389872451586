import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isApp, IfMobile } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';

const ScannerTemplate = ({ buttonText = 'Scan Ticket', ticket = { success: false } }) => {
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            window.open('post_claim');
        }
    };

    const scanCallback = async (infoObject) => {
        // SCL uses ?ticketnumber=<ticketNumber> so this code should never run
        console.log('🟢 Finished scan: ', infoObject);

        // await actions.drawTicketActions.enterDrawTicket({
        //     ticket1_1_1: infoObject.result,
        // });
    };

    // fire the submission
    const handleSubmit = async (event) => {
        setDisabled(true);
        event.preventDefault();

        if (isApp) {
            console.log('🟡 Trigger: scanBarcode');
            await window.MdiNativeShell.scanBarcode(scanCallback);
        } else {
            console.warn('🔴 Error: invalid app');
        }

        setDisabled(false);
    };

    return (
        <IfMobile>
            <div className='text-center'>
                <button disabled={disabled} onClick={handleSubmit} type='button' className='scan-tickets-btn'>
                    {buttonText}
                </button>
            </div>
        </IfMobile>
    );
};

const Scanner = connect(mapStateToProps, mapDispatchToProps)(ScannerTemplate);

export default Scanner;
