import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CMSContent, ErrorMessage, TeleScript, getCMSObject, FindScratchNumbers, AlertBox } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validateScratcherEntry } from '../../validationRules';
import { Redirect } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import siteConfig from '../../promotionConfig';
import { replaceBadTicketInput } from '../../utils/replaceBadTicketInput';

import Scanner from './Scanner';
import ScratchFieldInput from './ScratchFieldInput';

/**********************************************************************
 * Component:  ScratchTicketForm
 * Purpose:    Allows for the entry of Scratch Tickets
 *
 * Props:       user - the user data store
 *              actions - store actions (apis)
 *              scratchTicket - the scratch ticket data store
 *
 * APIs used:   scratchTicketActions.enterScratchTicket
 *
 *  Notes:  For MO, does not check Winning Status, only for
 *          bonusing rewards.
 */
const ScratchTicketForm = ({ user, actions, scratchTicket, cmsSourceFirebase }) => {
    const scratchTicketFields = siteConfig.scratchTicketFields;
    const ticketEntryLink = getCMSObject('data.ticketEntry.ticketHistoryLink.contentHTML');

    const [gameNumberFields, setGameNumberFields] = useState(scratchTicketFields?.gameNumberFields ?? []);
    const [scratchFrontFields, setScratchFrontFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.frontNumberFields ?? []);
    const [scratchBackFields, setScratchBackFields] = useState(scratchTicketFields?.fieldGroups?.[0]?.backNumberFields ?? []);
    const [ticketError, setTicketError] = useState('');
    const [validationError, setValidationError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [ticketSuccess, setTicketSuccess] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const elemRefs = [{}]; // Defaulted to one element to avoid problem with first tab
    const ticketNumberSecondInputRef = useRef();

    //***********************************************************************************
    // Processes the ticket status and if the ticket was a success, redirect to
    // Post Claim.
    // If Ticket fails, report the error.  If failure was due to Player Lockout, prevent
    // further submitting of tickets.
    //
    useEffect(() => {
        if (disabled) {
            if (scratchTicket.success) {
                updateDashboard();
                setTicketSuccess(true);
            } else {
                setTicketError(scratchTicket.message);
            }
            setDisabled(false);
        }
        if (scratchTicket.message === '_PLAYER_LOCKOUT') {
            setDisabled(true);
        }
    }, [scratchTicket]);

    // Cleanup
    useEffect(() => {
        return () => {
            // clean up and make sure fields are clear
            gameNumberFields.forEach((field) => {
                field.value = '';
            });
            scratchFrontFields.forEach((field) => {
                field.value = '';
            });
            scratchBackFields.forEach((field) => {
                field.value = '';
            });
            setGameNumberFields([]);
            setScratchFrontFields([]);
            setScratchBackFields([]);
            setTicketError('');
            setValidationError('');
            setDisabled(false);
            setTicketSuccess(false);
            setShowModel(false);
        };
    }, []);
    //***********************************************************************************
    // Change the Scratch ticket format based on the game number field.
    //
    useEffect(() => {
        const gameNum = gameNumberFields[0].value;
        scratchTicketFields.fieldGroups.forEach((fieldGroup) => {
            if (inRange(gameNum, fieldGroup)) {
                setScratchFrontFields(fieldGroup.frontNumberFields ?? []);
                setScratchBackFields(fieldGroup.backNumberFields ?? []);
                return;
            }
        });
    }, [gameNumberFields]);

    //***********************************************************************************
    // Updates the dashboard on a ticket entry, see if points have been updated
    //
    const updateDashboard = async () => {
        if (user.loggedIn && user.player.actions.length === 0) {
            await actions.userActions.getDashboard();
        }
    };

    // We need to mark the input fields with missing characters which caused the validation error
    const checkMissingCharactersOnValidationError = (field) => {
        return (validationError && (parseInt(field?.size) !== field?.value?.length)) ? 'theme-input-validation-error' : '';
    }

    //***********************************************************************************
    // Cycle through the draw fields and set up the input boxes.
    // Autotabbing should be allowed to go from input to input.
    const ScratchFieldInputs = (fields) => {
        return fields.fields.map((field, index) => {
            // to keep front numbers from sharing same index as back numbers
            const ref = React.createRef();
            elemRefs.push(ref);
            return <ScratchFieldInput key={`scratch-fields--${index + 1}`} field={field} handleFieldChange={handleFieldChange} ref={ref} elemRefs={elemRefs} checkMissingCharactersOnValidationError={checkMissingCharactersOnValidationError} />;
        });
    }; // end DrawFieldInputs

    //***********************************************************************************
    // Cycle through the Game ID fields and set up the input boxes.
    // Autotabbing should be allowed to go from input to input.
    const GameIdFieldInputs = () => {
        return gameNumberFields.map((field, index) => {
            const ref = React.createRef();
            elemRefs.push(ref);
            return (
                <div className='d-inline-block ticket-number-inputs' key={`game-numbers--${index}`}>
                    <input
                        type='text'
                        pattern={siteConfig?.propsScratchFieldInput?.pattern}
                        inputMode={siteConfig?.propsScratchFieldInput?.inputmode}
                        id={field.name}
                        data-index={field.dataindex}
                        ref={ref}
                        className={`theme-input text-center ${checkMissingCharactersOnValidationError(field)}`}
                        placeholder={field.placeholder}
                        size={field.size}
                        maxLength={field.size}
                        defaultValue={field.value}
                        onBlur={(event) => handleGameNumberChange(event, field)}
                        onKeyUp={(event) => {
                            if (event.target.value.length >= event.target.maxLength) {
                                ticketNumberSecondInputRef.current.focus();
                            }
                        }}
                        required
                    />
                    <span className='dash'>&mdash;</span>
                </div>
            );
        });
    }; // end GameIdFieldInputs

    //***********************************************************************************
    // handle a change in the game number and update the game fields accordingly.
    //
    const handleGameNumberChange = async (event, field) => {
        setTicketError('');
        setValidationError('');
        // get gameNum from the event target
        const gameNum = replaceBadTicketInput(event.target);

        // do a deep copy of the gameNumberField state.
        let gameIdField = JSON.parse(JSON.stringify(gameNumberFields));
        // check that we are changing the right index in the array
        const fieldIndex = gameIdField.findIndex((obj) => obj.name == field.name);
        gameIdField[fieldIndex].value = gameNum.value;
        setGameNumberFields(gameIdField);
    }; // end handleGameNumberChange

    // Find if the given number is in this range grouping
    const inRange = (number, rangeGroup) => {
        let numberInRange = false;
        rangeGroup.validRanges.forEach((range) => {
            if (parseInt(number) >= parseInt(range[0]) && parseInt(number) <= parseInt(range[1])) {
                numberInRange = true;
                return;
            }
        });
        return numberInRange;
    }; // end inRange

    //***********************************************************************************
    // onChange callback for the input fields.  Sets the state of the front and back
    // fields
    //
    const handleFieldChange = async (event, field) => {
        setTicketError('');
        setValidationError('');
        const userInput = replaceBadTicketInput(event.target);
        if (field.side === 'front') {
            const frontFields = scratchFrontFields;
            const fieldIndex = frontFields.findIndex((obj) => obj.name == field.name);
            frontFields[fieldIndex].value = userInput.value;
            setScratchFrontFields(frontFields);
        } else {
            const backFields = scratchBackFields;
            const fieldIndex = backFields.findIndex((obj) => obj.name == field.name);
            backFields[fieldIndex].value = userInput.value;
            setScratchBackFields(backFields);
        }
    }; // end handleFieldChange

    //***********************************************************************************
    // Handle the submit of the scratch ticket.  Combine all the tickets fields into
    // one string object that is passed to the ScratchTicket API and disable submit
    // until we get a response.
    //
    const handleScratchSubmit = async () => {
        setDisabled(true);
        setValidationError('');
        setTicketSuccess(false);
        let ticketString = '';

        gameNumberFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        scratchBackFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        scratchFrontFields.forEach((field) => {
            ticketString = ticketString + field.value;
        });

        const scratchEntrySchema = validateScratcherEntry;
        const valid = await scratchEntrySchema.isValid(ticketString);

        if (valid) {
            await actions.scratchTicketActions.enterScratchTicket({
                ticket_number: ticketString,
                entry_method: 'manual',
                ticket_type: 'instant',
            });
        } else {
            await scratchEntrySchema.validate(ticketString, { abortEarly: true }).catch(function (err) {
                setValidationError(err.message);
            });
        }
        setDisabled(false);
    }; // end handleScratchSubmit

    const showScratcherTooltip = () => {
        setShowModel(true);
    };

    const handleModalClose = () => {
        setShowModel(false);
    };

    return (
        <div>
            {ticketSuccess ? <Redirect to={{ pathname: '/post-claim', state: { motive: 'TicketEntry' }, motive: 'TicketEntry' }} /> : null}

            <AlertBox message={validationError} />
            <ErrorMessage code={ticketError} collection='data.messages.ticketErrorMessages.jsonBlock' />
            <Scanner />

            <div className='row'>
                <div className='col-12'>
                    <div className='enter-form back-numbers'>
                        <label>Ticket Number</label>
                        <GameIdFieldInputs />
                        {scratchBackFields.length && (
                            <>
                                {/*  Render the first of the scratch field inputs with special ref */}
                                <ScratchFieldInput
                                    key={`scratch-fields--${0}`}
                                    field={scratchBackFields[0]}
                                    index={0}
                                    handleFieldChange={handleFieldChange}
                                    className='theme-input text-center'
                                    ref={ticketNumberSecondInputRef}
                                    elemRefs={elemRefs}
                                    checkMissingCharactersOnValidationError={checkMissingCharactersOnValidationError}
                                />
                                {/*  Render the rest of the scratch field inputs with normal refs */}
                                <ScratchFieldInputs fields={scratchBackFields.slice(1)}/>
                            </>
                        )}
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' className='ticket-tooltip' onClick={() => showScratcherTooltip()} />
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12 col-sm-7'>
                    <div className='enter-form front-numbers align-middle'>
                        <label>Entry Number</label>
                        <ScratchFieldInputs fields={scratchFrontFields} />
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' className='ticket-tooltip' onClick={() => showScratcherTooltip()} />
                    </div>
                </div>
                <div className='col-12 col-sm-5'>
                    <div className='enter-form submit-view'>
                        <button type='submit' className='btn theme-btn theme-primary mt-3' disabled={disabled} onClick={() => handleScratchSubmit()}>
                            Submit
                        </button>
                        <HashLink to='/my-activity#ticket-history' className='view-history-link d-block mt-1'>
                            <TeleScript line={ticketEntryLink}>View your ticket history</TeleScript>
                        </HashLink>
                    </div>
                </div>
            </div>

            <Modal show={showModel} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.ticketEntry.findScratchNumbers.contentHeaderText'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FindScratchNumbers.ScratchNumberExample cmsSourceFirebase={cmsSourceFirebase} />
                </Modal.Body>

                <Modal.Footer>
                    <Button className='btn theme-btn theme-secondary' onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ScratchTicketForm);
