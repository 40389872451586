import React from 'react';
import { autoTab } from 'sg-ui-components';

import siteConfig from '../../promotionConfig';

const ScratchFieldInput = React.forwardRef(({ field, handleFieldChange, elemRefs, checkMissingCharactersOnValidationError = () => {}}, ref) => {
    return (
        <div className='d-inline-block ticket-number-inputs'>
            <input
                type='text'
                pattern={siteConfig?.propsScratchFieldInput?.pattern}
                inputMode={siteConfig?.propsScratchFieldInput?.inputmode}
                id={field.name}
                data-index={field.dataindex}
                ref={ref}
                className={`theme-input text-center ${checkMissingCharactersOnValidationError(field)}`}
                placeholder={field.placeholder}
                size={field.size}
                maxLength={field.size}
                defaultValue={field.value}
                onChange={(event) => handleFieldChange(event, field)}
                onKeyUp={(event) => {
                    autoTab(event, elemRefs);
                }}
                required
            />
            <span className='dash'>&mdash;</span>
        </div>
    );
});

ScratchFieldInput.displayName = 'ScratchFieldInput';

export default ScratchFieldInput;
