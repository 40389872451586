import React from 'react';
import { getCMSObject, WinnerLink } from 'sg-ui-components';

const telescriptWinnerLink = getCMSObject('data.components.teleScripts.WinnerLink.jsonBlock');

const WinnerLinkWrapper = ({ selected }) => {
    //* Special winners format for South Carolina
    const displayHeadersOverride = {
        city: 'City',
        state: 'State',
        prize: 'Prize',
    };

    const displayRowsOverride = (entries) => {
        return entries.map((entry) => ({
            ...entry,
            fields: {
                city: entry.city ?? '',
                state: entry.state ?? '',
                prize: entry.prize_name ?? entry.tier ?? '',
            },
        }));
    };

    return (
        <WinnerLink
            selected={selected}
            telescript={telescriptWinnerLink}
            displayHeadersOverride={displayHeadersOverride}
            displayRowsOverride={displayRowsOverride}
        />
    );
};

export default WinnerLinkWrapper;
