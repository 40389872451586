import React, { useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { camelCase } from 'lodash';
import {
    IfNotMobile,
    CMSContent,
    TitleBreadcrumb,
    StyleOverrides,
    getInterpolatedPageData,
    scrollToHashElement,
    ErrorBoundary,
    TeleScript,
    getCMSObject,
    FeatureLockout,
} from 'sg-ui-components';

import { Header } from './Layouts/Header';
import WrapperHeader from './Layouts/Wrapper/WrapperHeader';
import WrapperFooter from './Layouts/Wrapper/WrapperFooter';
import IdleTimeout from './IdleTimeout';

import { testing, seoDefaultTitle, routes } from '../promotionConfig';
import medalliaSetUserInfo from '../utils/medalliaSetUserInfo';

const Layout = ({ user, config, cmsSourceFirebase, actions, children, match, configReplacements }) => {
    const path = match?.path ?? '';
    const pageData = getInterpolatedPageData(routes, match, configReplacements);
    const errorBoundaryFallback = getCMSObject('data.messages.pageErrorMessages.jsonBlock')?.[`${camelCase(path) || 'home'}Fallback`];

    const canDisplayTitleBreadcrumb = () => {
        if (user?.loggedIn) {
            return true;
        }

        if (pageData?.userLockout) {
            return false;
        }

        return true;
    };
    //? Note: Frontend config will be requested and stored on first pageload / refresh
    useLayoutEffect(() => {
        async function configStateActions() {
            await actions.configActions.getEnvironmentConfig();
        }

        if (!config?.config?.firebase) {
            configStateActions();
            //? For debug: console.log('🟢 configStateActions()');
        }
    }, []);

    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    useLayoutEffect(() => {
        async function pageStateActions() {
            // Pull CMS data to local storage
            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webConfig',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webContent',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webMenu',
            });

            await actions.cmsSourceFirebaseActions.setLoaded(true);

            // Set config store
            const webConfigData = getCMSObject('data', 'webConfig') ?? {};
            await actions.configActions.setWebConfig(webConfigData);

            const siteConfigData = { routes: routes };
            await actions.configActions.setSiteConfig(siteConfigData);

            await actions.configActions.setLoaded(true);

            scrollToHashElement();
        }

        if (config?.config?.firebase && !cmsSourceFirebase?.loaded) {
            pageStateActions();
            //? For debug: console.log('🟢 pageStateActions()');
        }
    }, [config]);

    useLayoutEffect(() => {
        async function playerStateActions() {
            await actions.userActions.getPlayerData();

            if (user.loggedIn) {
                await actions.revealTokenActions.getRevealToken();
                medalliaSetUserInfo(user);
            } else {
                // SSO from SCEL Lottery
                const hash = new URLSearchParams(document.location.search).get('hash');
                if (hash) {
                    await actions.userActions.login({ hash: hash, pta_version: 'v3' });
                }
            }
            scrollToHashElement();
        }
        playerStateActions();
        //? For debug: console.log('🟢 playerStateActions()');
    }, [user?.loggedIn]);

    // Google Tag Manager initialization
    if (config?.config?.gtm_id) {
        ReactGA.initialize(config?.config?.gtm_id);
    }

    const isLoaded = config?.loaded && cmsSourceFirebase.loaded;

    if (!isLoaded) {
        return (
            <React.Fragment key='loading-indicator-spection'>
            <div className='d-flex justify-content-center bg-white'>
                <div className='loading-indicator' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
            </div>
        </React.Fragment>)
    }

    return (
        <FeatureLockout
            config={config}
            page={pageData}
            user={user}
            options={{ redirectPath: pageData?.featureDisabledRedirectPath }}>
            <Helmet defaultTitle={seoDefaultTitle} titleTemplate={`%s | ${seoDefaultTitle}`}>
                <title>{pageData?.title}</title>
            </Helmet>
            <IfNotMobile>
                <WrapperHeader path={path} />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-top' noticePlacement='web-notice-top-fixed' />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-top' />
            </IfNotMobile>
            <div className={`content-root ${pageData?.className}`}>
                <Header />
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='' noticePlacement='web-notice-below-header' />
                {!cmsSourceFirebase?.loaded ? (
                    <React.Fragment key='loading-indicator-spection'>
                        <div className='d-flex justify-content-center'>
                            <div className='loading-indicator' role='status'>
                                <span className='sr-only'>Loading...</span>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <ErrorBoundary fallback={<TeleScript line={errorBoundaryFallback} />} details={{ pageData, user, config }}>
                        <div className={`${pageData?.className}-container page-theme`}>
                            <div className='galaxy-fold-open-your-device d-none'></div>
                            <div className='layout container'>
                                <div className='main'>
                                    <div className='page-content w-100'>
                                        <div className='bread-box'>
                                            {path && canDisplayTitleBreadcrumb() && <TitleBreadcrumb pageData={pageData} />}
                                        </div>
                                        {children}
                                    </div>
                                </div>
                            </div>
                            <IdleTimeout />
                        </div>
                    </ErrorBoundary>
                )}
            </div>
            <IfNotMobile>
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-bottom' noticePlacement='web-notice-bottom-fixed' />
                <WrapperFooter />
            </IfNotMobile>
            <StyleOverrides cmsSourceFirebase={cmsSourceFirebase} />
        </FeatureLockout>
    );
};

export default Layout;
