import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BannerCarousel } from 'sg-ui-components';
import { mapDispatchToProps, mapStateToProps } from '../Store';
import ProgressiveJackpot from '../Components/ProgressiveJackpot/ProgressiveJackpot';
import promotionConfig from '../promotionConfig';

import './banner_carousel_syles.scss';

const PromotionBanner = ({ banners, actions }) => {
    const bannerName = promotionConfig?.banners?.promotionCarousel ?? '';
    const show = banners[bannerName]?.length;

    useEffect(() => {
        async function fetchData() {
            if (!banners[bannerName] || banners[bannerName]?.length === 0) {
                await actions.bannerActions.getBanners(bannerName);
            }
        }

        fetchData();
    }, []);

    if (show) {
        return (
            <div className='promotion-carousel'>
                <BannerCarousel banners={banners} bannerName={bannerName} ProgressiveJackpot={ProgressiveJackpot} />
            </div>
        );
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionBanner);
