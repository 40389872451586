import { VirtualCounter } from './VirtualCounter';

export class VirtualCounterManager {
    _counters = new Map();

    constructor() {
        // eslint-disable-next-line no-prototype-builtins
        if (VirtualCounterManager.hasOwnProperty('Instance')) return VirtualCounterManager.Instance;
        Object.defineProperty(VirtualCounterManager, 'Instance', {
            value: this,
            enumerable: false,
            writable: false,
            configurable: false,
        });
    }

    /**
     * Fetches the requested endpoint or creates a new one
     * @param endpoint {string}
     */
    getCounter(endpoint) {
        if (this._counters.has(endpoint)) {
            return this._counters.get(endpoint);
        } else {
            return this._counters.set(endpoint, new VirtualCounter(endpoint));
        }
    }
}
