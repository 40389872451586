import React from 'react';
import { Link } from 'react-router-dom';

import { mergeCopy } from '../../../utils/cms_copy_provider';
import { LoginClick, RegisterClick, LogoutClick } from './login';

import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';

const WelcomeTemplate = ({ user, children }) => {
    const renderName = (user) => {
        if (user.loggedIn) {
            return (
                <p>
                    <span className='welcome-text'>Welcome,</span>
                    <span className='user-name'>{user.player.firstname}!</span>
                </p>
            );
        } else {
            return (
                <p>
                    <span className='welcome-text'>Welcome!</span>
                </p>
            );
        }
    };

    return (
        <div className='welcome'>
            {renderName(user)}
            {children}
        </div>
    );
};

const Welcome = connect(mapStateToProps, mapDispatchToProps)(WelcomeTemplate);

const LogoutButtonTemplate = ({ user, config, actions, copy = {}, children }) => {
    const defaultCopy = {
        welcome: {
            logout: 'Log Out',
        },
    };

    const usedCopy = mergeCopy(defaultCopy, copy);

    const logoutAction = LogoutClick(config, actions);

    const logoutClick = async (e) => {
        logoutAction();
        e.preventDefault();
    };

    if (user.loggedIn) {
        return (
            <Link className='' onClick={logoutClick}>
                {children || usedCopy.welcome.logout}
            </Link>
        );
    } else {
        return null;
    }
};

const LogoutButton = connect(mapStateToProps, mapDispatchToProps)(LogoutButtonTemplate);

const LoginButtonTemplate = ({ user, config, copy = {}, children }) => {
    const defaultCopy = {
        welcome: {
            login: 'Log In',
        },
    };

    const usedCopy = mergeCopy(defaultCopy, copy);

    const loginAction = LoginClick(config);

    const loginClick = async (e) => {
        loginAction();
        e.preventDefault();
    };

    if (user.loggedIn) {
        return null;
    } else {
        return (
            <Link onClick={loginClick} className=''>
                {children || usedCopy.welcome.login}
            </Link>
        );
    }
};

const LoginButton = connect(mapStateToProps, mapDispatchToProps)(LoginButtonTemplate);

const RegisterButtonTemplate = ({ user, config, copy = {}, children }) => {
    const defaultCopy = {
        welcome: {
            register: 'Register',
        },
    };

    const usedCopy = mergeCopy(defaultCopy, copy);

    const registerAction = RegisterClick(config);

    const registerClick = async (e) => {
        registerAction();
        e.preventDefault();
    };

    if (user.loggedIn) {
        return null;
    } else {
        return (
            <Link className='' onClick={registerClick}>
                {children || usedCopy.welcome.register}
            </Link>
        );
    }
};

const RegisterButton = connect(mapStateToProps, mapDispatchToProps)(RegisterButtonTemplate);

const AccountButtonTemplate = ({ user, copy = {}, children }) => {
    const defaultCopy = {
        welcome: {
            account: 'My Profile',
        },
    };

    const usedCopy = mergeCopy(defaultCopy, copy);

    if (user.loggedIn) {
        return (
            <Link to='/my-profile' className=''>
                {children || usedCopy.welcome.account}
            </Link>
        );
    } else {
        return null;
    }
};

const AccountButton = connect(mapStateToProps, mapDispatchToProps)(AccountButtonTemplate);

export { Welcome, LogoutButton, LoginButton, RegisterButton, AccountButton };
