import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCMSObject, PromotionDetails } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import { jurisdiction } from '../promotionConfig';

const PromotionDetailsPage = ({ user, config, promotion, cmsSourceFirebase, actions, match }) => {
    const telescriptPromotionDetails = getCMSObject('data.components.teleScripts.promotionDetails.jsonBlock');

    const [promotionName, setPromotionName] = useState('Promotion Details');

    return (
        <Layout
            {...{
                user,
                config,
                cmsSourceFirebase,
                actions,
                match,
                configReplacements: [
                    {
                        find: '{{PROMOTION_NAME}}',
                        replace: promotionName ?? '',
                    },
                ],
            }}>
            <PromotionDetails
                user={user}
                config={config}
                promotion={promotion}
                actions={actions}
                jurisdiction={jurisdiction}
                telescript={telescriptPromotionDetails}
                setPageTitle={setPromotionName}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionDetailsPage);
