import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const PrivacyPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            {hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHTML') && (
                <React.Fragment key='Privacy Policy section'>
                    <BoxedContent
                        note='Privacy section'
                        hash='privacy'
                        isActive={true}
                        hasCollapser={false}
                        isVisible={hasCMSContent('data.legalAndPolicyItems.privacyPolicy.contentHeaderText')}
                        title={
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.legalAndPolicyItems.privacyPolicy.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        }>
                        <CMSContent
                            localStorageObject='webContent'
                            contentPath='data.legalAndPolicyItems.privacyPolicy.contentHTML'
                            cmsSourceFirebase={cmsSourceFirebase}
                        />
                    </BoxedContent>
                </React.Fragment>
            )}
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
