//* Setting up Medallia with user token and player id

const medalliaSetUserInfo = (user) => {
    const cookieMedallia = document.cookie.split(';').filter((i) => i.includes('incap'));
    const player_id = user?.player?.player_id?.length ? user?.player?.player_id : user?.player?.registrar_id;
    const combinedMedalliaData = [cookieMedallia, player_id];
    window.medalliaUserData = combinedMedalliaData;

    //? For debug: console.log('🔵 Medallia user info:', combinedMedalliaData);
};

export default medalliaSetUserInfo;
