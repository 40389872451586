/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const CREATEJS_LIB = 'https://code.createjs.com/createjs-2015.11.26.min.js';

async function loadScript(src) {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    return new Promise((resolve) => {
        const scripts = document.getElementsByTagName('script')[0];
        scripts.parentNode.insertBefore(script, scripts);

        script.onload = resolve;
    });
}

const handleFileLoad = (evt, comp) => {
    const images = comp.getImages();
    if (evt && evt.item.type === 'image') {
        images[evt.item.id] = evt.result;
    }
};

//Registers the "tick" event listener.
const fnStartAnimation = (lib, stage, exportRoot) => {
    const createjs = window.createjs;

    /* eslint-disable eqeqeq */
    stage.addChild(exportRoot);
    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener('tick', stage);
    stage.addEventListener('tick', handleTick);
    function getProjectionMatrix(container, totalDepth) {
        var focalLength = 528.25;
        var projectionCenter = {
            x: lib.properties.width / 2,
            y: lib.properties.height / 2,
        };
        var scale = (totalDepth + focalLength) / focalLength;
        var scaleMat = new createjs.Matrix2D();
        scaleMat.a = 1 / scale;
        scaleMat.d = 1 / scale;
        var projMat = new createjs.Matrix2D();
        projMat.tx = -projectionCenter.x;
        projMat.ty = -projectionCenter.y;
        projMat = projMat.prependMatrix(scaleMat);
        projMat.tx += projectionCenter.x;
        projMat.ty += projectionCenter.y;
        return projMat;
    }
    function handleTick(event) {
        var cameraInstance = exportRoot.___camera___instance;
        if (cameraInstance !== undefined && cameraInstance.pinToObject !== undefined) {
            cameraInstance.x = cameraInstance.pinToObject.x + cameraInstance.pinToObject.pinOffsetX;
            cameraInstance.y = cameraInstance.pinToObject.y + cameraInstance.pinToObject.pinOffsetY;
            if (cameraInstance.pinToObject.parent !== undefined && cameraInstance.pinToObject.parent.depth !== undefined)
                cameraInstance.depth = cameraInstance.pinToObject.parent.depth + cameraInstance.pinToObject.pinOffsetZ;
        }
        applyLayerZDepth(exportRoot);
    }
    function applyLayerZDepth(parent) {
        var cameraInstance = parent.___camera___instance;
        var focalLength = 528.25;
        var projectionCenter = { x: 0, y: 0 };
        if (parent === exportRoot) {
            var stageCenter = {
                x: lib.properties.width / 2,
                y: lib.properties.height / 2,
            };
            projectionCenter.x = stageCenter.x;
            projectionCenter.y = stageCenter.y;
        }
        for (let child in parent.children) {
            var layerObj = parent.children[child];
            if (layerObj == cameraInstance) continue;
            applyLayerZDepth(layerObj, cameraInstance);
            if (layerObj.layerDepth === undefined) continue;
            if (layerObj.currentFrame != layerObj.parent.currentFrame) {
                layerObj.gotoAndPlay(layerObj.parent.currentFrame);
            }
            var matToApply = new createjs.Matrix2D();
            var cameraMat = new createjs.Matrix2D();
            var totalDepth = layerObj.layerDepth ? layerObj.layerDepth : 0;
            var cameraDepth = 0;
            if (cameraInstance && !layerObj.isAttachedToCamera) {
                var mat = cameraInstance.getMatrix();
                mat.tx -= projectionCenter.x;
                mat.ty -= projectionCenter.y;
                cameraMat = mat.invert();
                cameraMat.prependTransform(projectionCenter.x, projectionCenter.y, 1, 1, 0, 0, 0, 0, 0);
                cameraMat.appendTransform(-projectionCenter.x, -projectionCenter.y, 1, 1, 0, 0, 0, 0, 0);
                if (cameraInstance.depth) cameraDepth = cameraInstance.depth;
            }
            if (layerObj.depth) {
                totalDepth = layerObj.depth;
            }
            //Offset by camera depth
            totalDepth -= cameraDepth;
            if (totalDepth < -focalLength) {
                matToApply.a = 0;
                matToApply.d = 0;
            } else {
                if (layerObj.layerDepth) {
                    var sizeLockedMat = getProjectionMatrix(parent, layerObj.layerDepth);
                    if (sizeLockedMat) {
                        sizeLockedMat.invert();
                        matToApply.prependMatrix(sizeLockedMat);
                    }
                }
                matToApply.prependMatrix(cameraMat);
                var projMat = getProjectionMatrix(parent, totalDepth);
                if (projMat) {
                    matToApply.prependMatrix(projMat);
                }
            }
            layerObj.transformMatrix = matToApply;
        }
    }
    /* eslint-enable eqeqeq */
};

let lastW,
    lastH,
    lastS = 1;

const resizeCanvas = (lib, canvas, stage, anim_container, isResp, respDim, isScale, scaleType) => {
    /* eslint-disable eqeqeq */
    var w = lib.properties.width,
        h = lib.properties.height;
    var iw = anim_container.parentElement.clientWidth,
        ih = anim_container.parentElement.clientHeight;
    var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
    if (isResp) {
        if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
            sRatio = lastS;
        } else if (!isScale) {
            if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 1) {
            sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType == 2) {
            sRatio = Math.max(xRatio, yRatio);
        }
    }
    canvas.width = w * pRatio * sRatio;
    canvas.height = h * pRatio * sRatio;
    // canvas.style.width = anim_container.style.width = w * sRatio + 'px';
    // canvas.style.height = anim_container.style.height = h * sRatio + 'px';
    stage.scaleX = pRatio * sRatio;
    stage.scaleY = pRatio * sRatio;
    lastW = iw;
    lastH = ih;
    lastS = sRatio;
    stage.tickOnUpdate = false;
    stage.update();
    stage.tickOnUpdate = true;
    /* eslint-enable eqeqeq */

    /**
     * This is a hack added by us to help with the timing between this resize and ours in RollingCount.js
     */
    const event = new Event('counterResize');
    canvas.dispatchEvent(event);
};

const AnimateCanvas = (props) => {
    const [ready, setReady] = useState(false);
    const [stage, setStage] = useState();
    const canvasRef = useRef();
    const containerRef = useRef();

    // Expose globals for exported scripts, must be applied first
    useEffect(() => {
        stage && (window.stage = stage);

        return () => {
            delete window.stage;
        };
    }, [stage]);

    // Load scripts
    useEffect(() => {
        // NOTE: TODO: this only checks if createjs loaded, if we have multiple instances
        // of this component with different props this will definitely not work
        if (window.createjs && window.AdobeAn) {
            setReady(true);
            return;
        }

        console.log('loading createjs ...');
        loadScript(props.createjsScript).then(() => {
            // console.log('createjs loaded');

            // console.log('loading scene ...');
            Promise.all([props.script, ...(props.externalScripts ? props.externalScripts : [])].map((src) => loadScript(src))).then(() => {
                // console.log('scene loaded');

                setReady(true);
            });
        });
    }, [props.script, props.createjsScript, props.externalScripts]);

    // Load assets and initialize scene
    useEffect(() => {
        if (!ready) {
            return;
        }

        const cleanup = () => {
            if (!stage) {
                return;
            }

            console.log('cleaning up stage');

            // clean-up
            window.createjs.Ticker.reset();
            window.createjs.Tween.removeAllTweens();
            window.createjs.Tween._inited = false;
            if (props.sounds) {
                window.createjs.Sound.removeAllSounds();
            }

            function cleanupNode(node) {
                node.children && node.children.forEach((child) => cleanupNode(child));
                node.removeAllEventListeners();
                node.removeAllChildren && node.removeAllChildren();
            }
            cleanupNode(stage);

            stage.enableDOMEvents(false);
            delete stage.canvas;

            setStage(undefined);
        };

        if (stage) {
            // stage is already initialized, just return the cleanup function
            return cleanup;
        }

        // Just get the first loaded comp
        const comp = Object.values(window.AdobeAn.compositions)[0];
        const lib = comp.getLibrary();

        const handleComplete = (evt) => {
            //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
            const lib = comp.getLibrary();
            const ss = comp.getSpriteSheet();
            const queue = evt.target;
            const ssMetadata = lib.ssMetadata;
            for (let i = 0; i < ssMetadata.length; i++) {
                ss[ssMetadata[i].name] = new window.createjs.SpriteSheet({
                    images: [queue.getResult(ssMetadata[i].name)],
                    frames: ssMetadata[i].frames,
                });
            }
            const exportRoot = new lib[props.name]();
            const stage = new lib.Stage(canvasRef.current);
            setStage(stage);

            window.AdobeAn.compositionLoaded(lib.properties.id);
            fnStartAnimation(lib, stage, exportRoot);
            if (props.onStageReady) {
                props.onStageReady(stage);
            }

            console.log('stage ready');
        };

        if (lib.properties.manifest && lib.properties.manifest.length > 0) {
            const loader = new window.createjs.LoadQueue(false, props.path ? props.path : '/');
            if (props.sounds) {
                loader.installPlugin(window.createjs.Sound);

                window.playSound = function (id, loop) {
                    var props = new window.createjs.PlayPropsConfig().set({
                        interrupt: createjs.Sound.INTERRUPT_NONE,
                        loop: 0,
                    });
                    return window.createjs.Sound.play(id, props);
                };
            }
            loader.addEventListener('fileload', (evt) => handleFileLoad(evt, comp));
            loader.addEventListener('complete', handleComplete);

            // clone the manifest because the load queue will alter it
            const manifest = lib.properties.manifest.map((entry) => ({
                ...entry,
            }));
            loader.loadManifest(manifest);
        } else {
            handleComplete({});
        }

        return cleanup;
    }, [ready, stage, props.sounds, props.name]);

    // Support hidpi screens and responsive scaling.
    useEffect(() => {
        if (!stage) {
            return;
        }

        // Just get the first loaded comp
        const comp = Object.values(window.AdobeAn.compositions)[0];
        const lib = comp.getLibrary();
        const resize = resizeCanvas.bind(
            null,
            lib,
            canvasRef.current,
            stage,
            containerRef.current,
            props.responsive !== 'none',
            props.responsive,
            props.scale !== 'none',
            props.scale === 'fit' ? 1 : 2
        );

        // This triggers the scene to start updating
        try {
            resize();
        } catch (e) {
            // No cleanup to do at this point
            return;
        }

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [stage, props.responsive, props.scale]);

    return (
        <>
            <style>
                {props.skin === 'yellowgold'
                    ? `
                .counter--label__yellowgold::after {
                    content: '${props.label}'
                }
            `
                    : ''}
            </style>
            <div ref={containerRef} className={`${props.cssClassName}${props.skin === 'yellowgold' ? ' counter--element__lower-label' : ''}`}>
                {props.label ? (
                    <div className='counter--label-wrapper'>
                        <div className={`counter--label counter--label__${props.skin}`}>{props.label}</div>
                    </div>
                ) : (
                    ''
                )}
                <canvas ref={canvasRef} width={props.width} height={props.height} />
            </div>
        </>
    );
};

AnimateCanvas.propTypes = {
    script: PropTypes.string.isRequired,
    createjsScript: PropTypes.string,
    externalScripts: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    responsive: PropTypes.oneOf(['none', 'width', 'height', 'both']),
    scale: PropTypes.PropTypes.oneOf(['none', 'fit', 'stretch']),
    sounds: PropTypes.bool,
    cssClassName: PropTypes.string,
    label: PropTypes.string,
    skin: PropTypes.string,
};

AnimateCanvas.defaultProps = {
    createjsScript: CREATEJS_LIB,
    responsive: 'none',
    scale: 'none',
    sounds: false,
};

export default AnimateCanvas;
