import React from 'react';
import { connect } from 'react-redux';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import AccountConfirmSCEL from '../Components/AccountConfirmSCEL';

const AccountConfirmPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <AccountConfirmSCEL />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirmPage);
