import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { validatePassword, validatePasswordDetailed } from '../../validationRules';

import { errorMessage } from '../errorMessages';

import { ContentBox, ContentBoxHead, ContentBoxBody, isBrowser, PasswordField, getCMSObject, TeleScript, CMSContent } from 'sg-ui-components';

const RecoverPassword = ({ loading, user, actions, cmsSourceFirebase }) => {
    //* Retrieve ID from url params
    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {
        passcode: urlParams.get('passcode'),
        mode: urlParams.get('mode'),
    };

    const initialStepFields = {
        password: '',
        confirm: '',
    };

    const schema = yup.object().shape({
        password: validatePassword,
        confirm: yup
            .string()
            .required('Password Confirmation is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [localErrors, setLocalErrors] = useState({});
    const loginTelescript = getCMSObject('data.components.teleScripts.login.jsonBlock');

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'password') {
            checkPassword(value);
        }
    };

    //  Inline validation for Password to make sure that it matches the password Schema
    const checkPassword = async (password) => {
        const passwordSchema = validatePasswordDetailed;

        const valid = await passwordSchema.isValid(password);

        if (valid) {
            setPasswordStatus('valid');
        } else {
            await passwordSchema.validate(password, { abortEarly: true }).catch(function (err) {
                setPasswordStatus(err.message);
            });
        }
    };

    const passwordStatusForm = (status) => {
        if (status === 'initial') return null;

        return (
            <>
                {status === 'valid' ? (
                    <div className='alert alert-success p-2 my-2' role='alert'>
                        Your password matches the criteria.
                    </div>
                ) : (
                    <div className='alert alert-danger p-2 my-2' role='alert'>
                        {status}
                    </div>
                )}
            </>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            await actions.loadingActions.setLoading({ action: 'recoverPassword' });

            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'recover-password', status: 'initial' });

            const payloadData = {
                password: stepFields.password,
                confirm: stepFields.confirm,
                passcode: queryParams.passcode,
            };

            await actions.userActions.recoverPassword(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    //* Passcode validation
    if (!queryParams.passcode) {
        window.location.href = '/forgot-password';
        return null;
    }

    //* reset success
    if (user.updateSection.section === 'recover-password' && user.updateSection.status === 'success') {
        return (
            <div className='login-container'>
                <div className='my-5' />
                <ContentBox variant='theme-blue'>
                    <ContentBoxBody>
                        <div className='form-step'>
                            <div className='inner-step text-center'>
                                <div>
                                    <CMSContent
                                        localStorageObject='webContent'
                                        contentPath='data.sitewideSettings.mainLogo.image'
                                        className='img-fluid'
                                        cmsSourceFirebase={cmsSourceFirebase}
                                    />
                                </div>
                                <p>Congratulations, your password has been updated.</p>
                                <p className='text-center'>
                                    <a href='/login' className='recover-btn w-50'>
                                        Continue to Login
                                    </a>
                                </p>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            </div>
        );
    }

    return (
        <div className='login-container'>
            <div className='my-5' />
            <ContentBox variant='theme-blue'>
                <ContentBoxHead>
                    <TeleScript line={loginTelescript?.updatePasswordTitle}>RESET MY PASSWORD</TeleScript>
                </ContentBoxHead>
                <ContentBoxBody>
                    <div className='form-step'>
                        <div className='inner-step'>
                            {error ? (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {Object.values(localErrors).map((err, index) => {
                                        return <p key={index}>{err}</p>;
                                    })}
                                </div>
                            ) : null}

                            {user.updateSection.section === 'recover-password' && user.updateSection.status === 'error' && (
                                <div className='alert alert-danger text-center' role='alert'>
                                    {errorMessage(user.updateSection.code)}
                                </div>
                            )}

                            <div className='form-group'>
                                <label htmlFor='password' className='label-class'>
                                    New Password
                                </label>
                                <TeleScript line={loginTelescript?.passwordRequirements}>
                                    <p>
                                        Your password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number and 1
                                        special character.{' '}
                                    </p>
                                </TeleScript>
                                <PasswordField name='password' value={stepFields.password} handleChange={handleChange} newPassword={true} />
                                {passwordStatusForm(passwordStatus)}
                            </div>

                            <div className='form-group'>
                                <label htmlFor='confirmPassword' className='label-class'>
                                    Confirm Password
                                </label>
                                <PasswordField name='confirm' value={stepFields.confirm} autoComplete='off' handleChange={handleChange} />
                                <small id='confirmHelp' className='form-text text-muted'>
                                    Please retype your password.
                                </small>
                            </div>
                            <div className='row justify-content-center'>
                                <button type='button' className='recover-btn mt-3' onClick={handleSubmit} disabled={loading.actions['recoverPassword']}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </ContentBoxBody>
            </ContentBox>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
