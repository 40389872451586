import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BoxedContent, getCMSObject, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import promotionConfig from '../promotionConfig';

const Logout = ({ user, config, cmsSourceFirebase, actions, match }) => {
    const telescript = getCMSObject('data.components.teleScripts.logout.jsonBlock');

    useEffect(() => {
        //? we need to make sure that the config is loaded before making the call
        if (config?.config?.lottery_host && user.loggedIn) {
            handleLogout();
        }
    }, [config, user]);

    const handleLogout = async () => {
        const myHost = window.location.protocol + '//' + window.location.host;
        const logoutDestination = promotionConfig.getLogoutDestination(myHost, config.config.logout_host_uri);

        window.location.search = '';
        await actions.userActions.logout();
        window.location.href = logoutDestination;
    };

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <BoxedContent note='Logout section' hash='logout' hasCollapser={false} title={telescript?.logoutHeaderTitle || 'Logout'}>
                <div className='my-2 text-center'><TeleScript line={telescript?.logoutSuccessMessage}>You have successfully been logged out.</TeleScript></div>
            </BoxedContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
