import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { BannerCarousel } from 'sg-ui-components';
import { mapDispatchToProps, mapStateToProps } from '../Store';
import ProgressiveJackpot from '../Components/ProgressiveJackpot/ProgressiveJackpot';
import promotionConfig from '../promotionConfig';

import './banner_carousel_syles.scss';

const allowedPaths = ['/', '/home'];

const HomeHeroBanner = ({ banners, actions }) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const bannerName = promotionConfig.banners.homeCarousel ?? '';

    const location = useLocation();
    const show = allowedPaths.some((path) => location.pathname === path) && banners[bannerName]?.length;

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                if (!banners[bannerName] || banners[bannerName]?.length === 0) {
                    await actions.bannerActions.getBanners(bannerName);
                }
                setPageLoaded(true);
            }
        }

        fetchData();
    }, []);

    if (show) {
        return (
            <div className='hero-carousel d-xs-block'>
                <BannerCarousel banners={banners} bannerName={bannerName} ProgressiveJackpot={ProgressiveJackpot} />
            </div>
        );
    } else {
        return null;
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeroBanner);
