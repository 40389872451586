import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IfMobile, IfNotMobile } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import ForgotPasswordPage from '../Components/Login/ForgotPassword';
import Layout from '../Components/Layout';

const ForgotPassword = ({ user, config, cmsSourceFirebase, actions, match }) => {
    const forgotPasswordUrl = config?.config?.lottery_host ? `${config?.config?.lottery_host}/PlayersClub/SendEmail/ForgotPassword` : '';

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <IfNotMobile>
                {user.loggedIn ? <Redirect to='/enter' /> : null}
                <div className='my-5 text-center'>
                    <h3>
                        Redirecting to forgot password page:
                        <a href={forgotPasswordUrl}>{!user.loggedIn && forgotPasswordUrl ? (window.location.href = forgotPasswordUrl) : null}</a>
                    </h3>
                </div>
            </IfNotMobile>
            <IfMobile>
                <ForgotPasswordPage />
            </IfMobile>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
