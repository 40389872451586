import React from 'react';
import { connect } from 'react-redux';
import { TeleScript, getCMSObject } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../../../Store';
import FooterMobileAppIcon_Apple from '../../../assets/images/FooterMobileAppIcon_Apple.png';
import FooterMobileAppIcon_Google from '../../../assets/images/FooterMobileAppIcon_Google.png';
import logo_white from '../../../assets/images/logo-white.png';

const WrapperFooter = ({ user, config }) => {
    const lotteryBaseUrl = config?.config?.lottery_host ?? 'https://www.sceducationlottery.com';
    const lotteryUrl = user?.loggedIn ? `/redirect?url=${lotteryBaseUrl}` : lotteryBaseUrl;

    return (
        <div className='wrapper-footer'>
            <footer>
                <div className='container'>
                    <TeleScript
                        note='footer top nav'
                        line={getCMSObject('data.lotteryWrapper.wrapperFooter.wrapperFooterTopNav.contentHTMLCode')}
                        replace={{
                            '%LOTTERY_URL%': lotteryUrl,
                        }}
                    />

                    <hr />
                    <div className='bottom-footer-nav'>
                        <div className='footer-logos'>
                            <div className='MobileApp-container'>
                                <a href='https://apps.apple.com/us/app/sc-lottery-app/id1467012089'>
                                    <img
                                        className='MobileApp-logo1'
                                        src={FooterMobileAppIcon_Apple}
                                        alt='South Carolina Education Lottery Mobile App Apple Store Link'
                                    />
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.sgi.scellottery&amp;hl=en'>
                                    <img
                                        className='MobileApp-logo2'
                                        src={FooterMobileAppIcon_Google}
                                        alt='South Carolina Education Lottery Mobile App Apple Store Link'
                                    />
                                </a>
                            </div>
                            <a href={lotteryUrl} rel='noreferrer' target='_blank'>
                                <img className='footer-logo' src={logo_white} alt='South Carolina Education Lottery' />
                            </a>
                        </div>
                        <div className='bottom-footer-sub-link-box'>
                            <TeleScript
                                note='footer sub footer links'
                                line={getCMSObject('data.lotteryWrapper.wrapperFooter.wrapperFooterSubfooterLink.contentHTMLCode')}
                                replace={{
                                    '%LOTTERY_URL%': lotteryUrl,
                                }}
                            />
                        </div>
                        <div className='disclaimer'>
                            <TeleScript
                                note='footer disclaimer text'
                                line={getCMSObject('data.lotteryWrapper.wrapperFooter.wrapperFooterDisclaimer.contentHTMLCode')}
                            />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperFooter);
